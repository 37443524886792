import React, {useEffect, useState, useRef} from 'react'
import {Actualizar, GetOrder} from './dbfirebase'
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js'
import check from '../images/check-48.png'

export const ModalFirmar = (row, {setUpdate}) => {
    
    const[motivo, setMotivo] = useState()
    const[validated, setValidated] = useState(false)

    const ordersData = useSelector(state => state.ordersInfo)

    const OrderRef = useRef(null)
    
    const closeModal = () => {
        const modal = document.getElementById('Modal2');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
        setValidated(false)
    };

    const handleCloseModal = () => {
         closeModal();
         
    };

    // window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);
    
    const dataId = async (row) =>{
        const textareaValue = document.getElementById("Firma").value
        const filteredData = ordersData.filter((item) =>{
            return item.Id === localStorage.getItem('IdOrder')
        })
        const ciphertext = filteredData[0].Firma
        const bytes  = CryptoJS.AES.decrypt(ciphertext, textareaValue);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        
        if (parseInt(filteredData[0].iddoc) === parseInt(originalText.replace(/"/g, ""))){
            setValidated(true)
            document.getElementById("Firma").value = ''
        }else{
            setValidated(false)
            document.getElementById("Firma").value = ''
        }
        
        const newstate = await Actualizar('Finalizado', {Fecha_finalizado: new Date().toLocaleString()},textareaValue);
        //row.setUpdate(true)
        //closeModal()
    }

    useEffect(()=>{
        localStorage.setItem('IdOrder', row.rowdata)
    },[row.rowdata])
    
    return (
        <div className="modal"
            id="Modal2"
            tabIndex={-1}
            aria-labelledby="Modal2"
            aria-hidden="true"
            style={{marginBottom:'20px' ,marginTop:'20px', overflow: "auto"}}ref={OrderRef}>
            <div className="modal-dialog modal-fullscreen-lg-down modal-lg">
                <div className='container modal-content' style={{ overflow: "auto",height:'23rem'}} >
                    <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button>
                    <h2>Validacion de firma</h2>
                    <p>Ingrese el numero generado en la cuenta del cliente.</p>
                        <div className=' col-12 d-flex justify-content-center'>
                            <input
                                 type = "text"
                                 name = "Firma"
                                 className = "form-control"
                                 placeholder = "Firma"
                                 id = "Firma"
                                 autoFocus
                                style={{marginTop:'20px', width:'100%'}}
                            />
                        </div>  
                        <div className=' col-12 d-flex justify-content-end'>    
                            <button className="btn btn-secondary" style={{height:'3rem', width:'7rem',marginTop:'20px' }} onClick={()=>{dataId()}}>
                                Confirmar
                            </button>
                        </div>
                        {validated && 
                        <div>
                            <p>{validated?"Firma Valida":"Firma Invalida"}</p>
                            <img src={validated?check:''} width='55rem' height='55rem' alt='' />
                        </div>}
                </div>
            </div>
        </div>
    );
  };