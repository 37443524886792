import { createSlice } from '@reduxjs/toolkit'

export const ManoDeObraSlice = createSlice({

    name: 'ManoDeObra',
    initialState: [],
    reducers: {
        addManoDeObra: (state, action) => {
            return action.payload
        },
    }
})


export const { addManoDeObra } = ManoDeObraSlice.actions
export default ManoDeObraSlice.reducer