import { createSlice } from '@reduxjs/toolkit'

export const RowInfoSlice = createSlice({

    name: 'RowInfo',
    initialState: [],
    reducers: {
        addRowInfo: (state, action) => {
            return action.payload
        },
    }
})
export const { addRowInfo } = RowInfoSlice.actions
export default RowInfoSlice.reducer