import React, {useEffect, useState, useRef} from 'react'
import {Actualizar, GetOrder} from './dbfirebase'
import { useMatch, useNavigate } from 'react-router-dom';
import { useAuth } from "../context/authcontext";
import factura from '../images/factura.png'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../components/firebase";
import imageCompression from 'browser-image-compression';


export const ModalCierre = () => {

    const match = useMatch('/ordenes/cerrado/:id')
    const id = match.params.id
    const navigate = useNavigate();
    const [validator,setValidator] = useState();
    const {user} = useAuth();
    const[motivo, setMotivo] = useState()
    const [image, setImage] = useState()
    const [imagePreview, setImagePreview] = useState('');
    const [downloadURL, setDownloadURL] = useState('');
    const [name, setName] = useState()

    const textarea = useRef(null);

    const getimage = async(event) => {
        const imagen = event.target.files[0];

        // Opciones de compresion de archivo
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(imagen, options); 
            setImage(compressedFile);
        } catch (error) {
            console.log(error);
          }
        // Previsualizar la imagen antes de subirla
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(imagen)
        // Obtener el nombre del archivo y actualizar el estado
        const fileName = imagen.name;
        setName(fileName);
    };

    const hoy = new Date();
    const anio = hoy.getFullYear().toString();
    const mes = (hoy.getMonth() + 1).toString().padStart(2, '0'); // Agrega un cero al principio si es necesario
    const dia = hoy.getDate().toString().padStart(2, '0'); // Agrega un cero al principio si es necesario

    const fechaSinSignos = anio + mes + dia;

    const uploadImage = async() => {
        if (image && name) {
            
            // Obtener la extensión del archivo desde el nombre
            const fileExtension = name.split('.').pop().toLowerCase();
    
            // Lista de extensiones permitidas
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'jfif'];
    
            // Verificar si la extensión es permitida
            if (allowedExtensions.includes(fileExtension)) {
                const storageRef = ref(storage, `Ordenes_Cerradas/${user.email + fechaSinSignos}/${id + "." +fileExtension}`);
                const metadata = {
                    contentType: `image/${fileExtension}`,
                };
    
                uploadBytes(storageRef, image, metadata)
                    .then(async(snapshot) => {
                        // console.log('Imagen subida exitosamente', snapshot);
                        // Obtener la URL de descarga de la imagen
                        const url = await getDownloadURL(storageRef);
                        setDownloadURL(url)
                        setValidator(true)
                        setImagePreview('');
                        setImage('');
                    })
                    .catch((error) => {
                        console.error('Error al subir la imagen:', error);
                    });
            } else {
                console.warn('Extensión de archivo no permitida. La imagen no se ha subido.');
            }
        } else {
            console.warn('Imagen o nombre de archivo no proporcionados. La imagen no se ha subido.');
        }
    };

    const dataId = async () =>{
        const textareaValue = textarea.current.value;
        const newstate = await Actualizar('Cerrado', {Fecha_Cierre: new Date().toLocaleString(), usuario: user.email}, textareaValue+" "+downloadURL, id); //Agregamos la URL a la orden como texto
        
        navigate('/ordenes')
        // Limpia el contenido del textarea
        textarea.current.value = "";
    }


    return (
        <div>
            <div>
                {/* <div style={{ overflow: "auto",height:'23rem'}} > */}
                <div>
                    <h2>Cierre de ordenes:</h2>
                    <p>Adjuntar documento firmado para validar cierre:</p>
                    <div className='row d-flex '>
                        <div className=' col-6 d-flex justify-content-center'>
                           <textarea rows='5' style={{marginTop:'20px', width:'100%'}} name='motivo' ref={textarea}>
                                
                            </textarea>
                        </div>
                        <div className='col-sm-4 d-flex justify-content-end image-upload ' style={{marginTop:'20px'}}>
                           
                            <input  type="file" accept="image/jpg" capture id='photodoc' onChange={getimage}></input>
                            {imagePreview && <img src={imagePreview} alt='Previsualización de imagen' style={{ maxWidth: '15rem', maxHeight: '15rem', margin: '0 40px 0 40px',marginTop:'20px' }} />}                            
                             <label htmlFor='photodoc'>Adjuntar conforme del cliente (*jpg)
                                <img src={factura} width='55rem' height='55rem' alt='' style={{ margin: '0 0 0 20px',marginTop:'20px'  }}  />
                            </label>
                            {image && <button className="btn btn-secondary" style={{height:'3rem', width:'15rem', margin:'5px' }} onClick={()=>{uploadImage()}}>
                                Subir 
                            </button>}
                        </div>  
                        
                    </div>
                </div>

    {downloadURL &&<div style={{marginTop:'20px', overflow: "auto", height:'auto'}} >
                        <p>Previsualizacion del archivo</p>
                        {downloadURL && <img src={downloadURL} alt='Imagen subida a Firebase' style={{ maxWidth: '100%', maxHeight: '100%' }}/>}
                        <div className=' col d-flex justify-content-center'>    
                           { validator &&  textarea && <button className="btn btn-success" style={{height:'3rem', width:'10rem',marginTop:'20px' }} onClick={()=>{dataId()}}> 
                                Confirmar cierre
                            </button> }
                    </div>
                </div>}
            </div>
        </div>
    );
  };