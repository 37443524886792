import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'

import { ProtectedRoute } from './components/protectedroutes'
import { Navbar } from './components/navbar'
import { Login } from './components/login'
import { Micuenta } from './components/micuenta'
import { Ordenes } from './components/ordenes'
import { AuthProvider, useAuth } from './context/authcontext'
import { GeneralProvider } from './context/generalContext'
import { NewOrder } from './components/neworders'
import { Terminos } from './components/terminos'
import { Repuestos } from './components/repuestos'
import { signOut } from 'firebase/auth';
import { auth } from "./components/firebase";
import { ListaPrecios } from "./components/listaprecios"
import { PedidosEstado } from "./components/pedidosEstado";
import { Guide } from "./components/Guide"
import {Manual} from "./components/Manual_ayuda"
import { ModalDiagnosticado } from './components/Modaldiagnosticado'
import { ModalPresupuestado } from './components/Modalpresupuesto'
import { ModalFinalizado } from './components/ModalFinalizado'
import { ModalDespachado } from './components/Modaldespacho'
import { ModalRechazado } from './components/Modalrechazado'
import { ModalCancelar } from './components/cancelarOrden'
import { ModificarOrder } from './components/ModificarOrden'
import { Facturacion } from './components/facturacion'
import { ModalCierre } from './components/ModalCierre'
import { ModalTable } from "./components/ModalTable";
import { ModalCentral } from "./components/ModalCentral";

function App() {
  const [logged, setLogged] = useState()
  
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Realiza aquí las acciones de limpieza al cerrar la página
  //     // borra los datos del usuario logeado o restablecer el estado de la aplicación
  //     navigate('/micuenta');
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [navigate]);


  return (
    <div className="App">
      <AuthProvider setLogged={setLogged}>
        <GeneralProvider logged = {logged}>
          <Router>
          <Navbar/>
            <div className="container p-4 body" style={{ minHeight: 'calc(100vh - 149px)', maxWidth: "90%"}}>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/micuenta" element={
                  <ProtectedRoute>
                    <Micuenta />
                  </ProtectedRoute>} />
                <Route path="/ordenes" element={
                  <ProtectedRoute>
                    <Ordenes />
                  </ProtectedRoute>} />
                <Route path="/nuevaorden" element={
                  <ProtectedRoute>
                    <NewOrder />
                  </ProtectedRoute>} />
                <Route path="/repuestos/:id" element={
                  <ProtectedRoute>
                    <Repuestos />
                  </ProtectedRoute>} />
                <Route path="/repuestos" element={
                  <ProtectedRoute>
                    <ListaPrecios />
                  </ProtectedRoute>} />
                <Route path="/estado_pedidos" element={
                  <ProtectedRoute>
                    <PedidosEstado />
                  </ProtectedRoute>} />
                <Route path = "/manual" element={
                    <ProtectedRoute>
                      <Manual/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/diagnosticado/:id" element={
                    <ProtectedRoute>
                      <ModalDiagnosticado/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/presupuestado/:id" element={
                    <ProtectedRoute>
                      <ModalPresupuestado/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/finalizado/:id" element={
                    <ProtectedRoute>
                      <ModalFinalizado/>
                    </ProtectedRoute>} />
                    <Route path="/ordenes/descargatabla/:id" element={
                    <ProtectedRoute>
                      <ModalTable/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/despacho/:id" element={
                    <ProtectedRoute>
                      <ModalDespachado/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/presupuestorechazado/:id" element={
                    <ProtectedRoute>
                      <ModalRechazado/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/cerrado/:id" element={
                    <ProtectedRoute>
                      <ModalCierre/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/cancelar/:id" element={
                    <ProtectedRoute>
                      <ModalCancelar/>
                    </ProtectedRoute>} />
                <Route path="/ordenes/modificaciones/:id" element={
                    <ProtectedRoute>
                      <ModificarOrder/>
                    </ProtectedRoute>} />
                    <Route path="/ordenes/central/:id" element={
                    <ProtectedRoute>
                      <ModalCentral />
                    </ProtectedRoute>} />    
                <Route path="/facturacion" element={
                    <ProtectedRoute>
                      <Facturacion/>
                    </ProtectedRoute>} />      
                <Route path="/terminosycondiciones" element={
                      <Terminos />} />

              </Routes>
            </div>
            <footer className="bottom-0 w-100 heightFooter text-center p-3" style={{ backgroundColor: "#1974AA", borderTop: "5px solid #ddd" }}>
              {/* <small style={{ color: 'white' }}>&copy; Goldmund&reg; | Todos los Derechos reservados.</small> */}
                <div className="row">
                  <div className="col d-none d-sm-block">
                  {/* Espacio vacío en la primera parte */}
                  </div>
                  <div className="col d-flex justify-content-center">
                    <small style={{ color: 'white' }}>&copy; Goldmund&reg; | Todos los Derechos reservados.</small>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <Link to="/manual" className="btn btn-primary">Manual</Link>
                  </div>
              </div>
            </footer>
          </Router>
        </GeneralProvider>
      </AuthProvider>

    </div>
  );
}

export default App;
