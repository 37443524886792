import React from 'react'
import { Table } from './table'
import { columnsPedidos } from './columns'
import { useSelector } from 'react-redux'
import { addRepuestosInfo } from '../store/repuestosInfo/repuestosInfoSlices'
import { useEffect } from 'react'
import {Ayuda} from './Help'

export const PedidosEstado = () => {

    const repuestosData = useSelector(state => state.repuestosInfo)
    
    return (

        <div>
            <div className='row' style={{ marginTop: '50px' }} >

                    <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Estado de pedidos</h3>
                    <Ayuda section="estado"/>

                {/* {dato && <Table showDetail={true} showComponents={true} listaOrdenes={dato} source={"orden"} settingcolumns={columnsOrdenes(setUpdate)} rows={dato.length} globalfilter={true} statefilter={true} exporttable={true} setUpdate={setUpdate} />} */}
            
            </div>
            <div style={{ marginTop: '50px' }}>
            <Table showComponents={true} listaOrdenes={repuestosData} settingcolumns={columnsPedidos} globalfilter={true} statefilterrepuestos={true}  exporttable={true} rows={repuestosData.length} />
            </div>
        </div>

    )
}
