import React, {useEffect, useState, useRef} from 'react'
import {Actualizar, GetOrder,ActualizarAcentral} from './dbfirebase'
import { useMatch, useNavigate } from 'react-router-dom';
import { useAuth } from "../context/authcontext";
import factura from '../images/factura.png'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../components/firebase";
import { useSelector } from 'react-redux';

export const ModalCentral = () => {
   

    const match = useMatch('/ordenes/central/:id')
    const id = match.params.id
    const navigate = useNavigate();
    const {user} = useAuth();
    const [motivo, setMotivo] = useState()
    const [image, setImage] = useState()
    const [imagePreview, setImagePreview] = useState('');
    const [downloadURL, setDownloadURL] = useState('');
    const [name, setName] = useState()
    const [validator,setValidator] = useState();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const ManoDeObraData = useSelector(state => state.manodeobra)

    const getCanjeDevolucion = () => {
        if (ManoDeObraData && ManoDeObraData.length > 0)  {
            const dato = ManoDeObraData[0]
            const preArray =  dato['1/2/2024'][0]
            const precio = preArray.CANJEDEVOLUCION
            
          return precio
          //retorna el precio pero se sube,  a firebse el Id y no se por que 
        }
        return null;
    };

    
    const dataId = async () => {
        const canje = getCanjeDevolucion(); // Llama a la función getCanjeDevolucion para obtener el valor del canje
        const newstate = await ActualizarAcentral('Envio a central', { Fecha_encentral: new Date().toLocaleString(), usuario: user.email }, id, canje);
        navigate('/ordenes');  
        // Limpia el contenido del textarea   
    }

    const getimage = (event) => {
        const imagen = event.target.files[0];
        setImage(imagen);
        // Previsualizar la imagen antes de subirla
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(imagen)
        // Obtener el nombre del archivo y actualizar el estado
        const fileName = imagen.name;
        setName(fileName);
    };

    const hoy = new Date();
    const anio = hoy.getFullYear().toString();
    const mes = (hoy.getMonth() + 1).toString().padStart(2, '0'); // Agrega un cero al principio si es necesario
    const dia = hoy.getDate().toString().padStart(2, '0'); // Agrega un cero al principio si es necesario

    const fechaSinSignos = anio + mes + dia;

    const uploadImage = async() => {
        
        if (image && name) {
            
            // Obtener la extensión del archivo desde el nombre
            const fileExtension = name.split('.').pop().toLowerCase();
            
            // Lista de extensiones permitidas
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'jfif', 'pdf'];
    
            // Verificar si la extensión es permitida
            if (allowedExtensions.includes(fileExtension)) {
                const storageRef = ref(storage, `OrdenComprobanteEnvio/${user.email}/${id + "." +fileExtension}`);
                const metadata = {
                    contentType: `image/${fileExtension}`,
                };
                setShowSuccessMessage(true)
                uploadBytes(storageRef, image, metadata)
                    .then(async(snapshot) => {
                        // console.log('Imagen subida exitosamente', snapshot);
                        // Obtener la URL de descarga de la imagen
                        const url = await getDownloadURL(storageRef);
                        



                        setValidator(true)
                        setImagePreview('');
                        setImage('');
                    })
                    .catch((error) => {
                        console.error('Error al subir la imagen:', error);
                    });
            } else {
                console.warn('Extensión de archivo no permitida. La imagen no se ha subido.');
            }
        } else {
            console.warn('Imagen o nombre de archivo no proporcionados. La imagen no se ha subido.');
        }
    };
    
    return (
        <div>
            <div>
                <h2>Comprobante de envío:</h2>
                <p>Adjuntar comprobante para validar el envío:</p>
                <div className='row d-flex '>
                    <div className='col-sm-4 d-flex justify-content-end image-upload' style={{ marginTop: '20px' }}>
                        <input type="file" accept="image/jpg, application/pdf" capture id='photodoc' onChange={getimage}></input>
                        {imagePreview && (
                            <div>
                                <img src={imagePreview} alt='Previsualización de imagen' style={{ maxWidth: '15rem', maxHeight: '15rem', margin: '0 40px 0 40px', marginTop: '20px' }} />
                                <button className="btn btn-secondary" style={{ height: '3rem', width: '15rem', margin: '5px' }} onClick={() => { uploadImage() }}>
                                    Subir
                                </button>
                            </div>
                        )}
                        {!imagePreview && (
                            <label htmlFor='photodoc'>Adjuntar Comprobante de envío (*jpg)
                                <img src={factura} width='55rem' height='55rem' alt='' style={{ margin: '0 0 0 20px', marginTop: '20px' }} />
                            </label>
                        )}
                    </div>
                </div>
                
            </div>
            <div style={{ marginTop: '10px', overflow: "auto", height: '80rem' }}>
                {showSuccessMessage && (
                    <div>
                        <p>¡La imagen se ha subido correctamente!</p>
                        <div className='col d-flex justify-content-center'>
                            <button className="btn btn-success" style={{ height: '3rem', width: '10rem', marginTop: '20px' }} onClick={() => { dataId() }}>
                                Confirmar envío
                            </button>
                        </div>
                    </div>
                )}

                <div>
            
            </div>
            </div>
        </div>
    );
  };