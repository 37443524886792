import React from 'react'

export const Terminos = () =>{

    return(
        <div>
            <h2>
            Términos y Condiciones
            </h2>
            <br/>
            <p>Por favor, lea estos términos y condiciones cuidadosamente antes de utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio web, acepta estar sujeto a estos términos y condiciones. Si no está de acuerdo con todos los términos y condiciones de este acuerdo, entonces no debería acceder al sitio web o utilizar cualquiera de los servicios.
            </p>
            <br/>
            <h5>Información del usuario
            </h5>
            <p>Al registrarse en nuestro sitio web, usted acepta proporcionar información precisa y completa sobre usted mismo. Usted también acepta que podemos acceder, recopilar y utilizar cualquier información que haya proporcionado públicamente en su cuenta de Google, como su dirección de correo electrónico, número de teléfono y otros detalles de contacto relevantes.
            </p>
            <h5>Seguridad y privacidad
            </h5>
            <p>Nos tomamos muy en serio la seguridad y la privacidad de su información personal. Tomamos medidas para garantizar que su información personal esté protegida contra el acceso no autorizado, el uso o la divulgación. Sin embargo, no podemos garantizar la seguridad absoluta de su información personal.
            </p>
            <h5>Uso aceptable
            </h5>
            <p>Al utilizar nuestro sitio web, usted acepta no utilizarlo para ningún propósito ilegal o prohibido por estos términos y condiciones. Además, se compromete a no utilizar nuestro sitio web para enviar, publicar o transmitir cualquier contenido que sea ilegal, dañino, amenazador, abusivo, difamatorio, vulgar, obsceno, calumnioso, invasivo de la privacidad de terceros, discriminatorio o que de alguna manera pueda ofender a otros usuarios.
            </p>
            <h5>Propiedad intelectual
            </h5>
            <p>Todos los derechos de propiedad intelectual del contenido publicado en nuestro sitio web, incluyendo, pero no limitado a, texto, gráficos, imágenes, videos, logotipos, marcas comerciales, son propiedad de nuestra empresa, de nuestros licenciatarios o desarrolladores. No se permite la reproducción, distribución o modificación del contenido sin nuestro consentimiento previo y por escrito.
            </p>
            <h5>Exclusión de garantías
            </h5>
            <p>Nuestro sitio web se proporciona "tal cual" y "según disponibilidad" sin garantía de ningún tipo, ya sea expresa o implícita. No garantizamos que nuestro sitio web esté libre de errores, virus, interrupciones, o que los resultados que se obtengan del uso de nuestro sitio web sean precisos, confiables o satisfactorios.
            </p>
            <h5>Limitación de responsabilidad
            </h5>
            <p>En ningún caso seremos responsables ante usted o cualquier tercero por daños directos, indirectos, incidentales, especiales o consecuentes que surjan del uso de nuestro sitio web, incluyendo, sin limitación, pérdida de ganancias, ingresos, datos o daños a la reputación.
            </p>
            <h5>Modificaciones a los términos y condiciones
            </h5>
            <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento y sin previo aviso. El uso continuado de nuestro sitio web después de cualquier modificación constituirá su aceptación de los términos y condiciones modificados.
            </p>
            <h5>Ley aplicable y jurisdicción
            </h5>
            <p>Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes del país donde nuestra empresa está ubicada, y cualquier disputa que surja en relación con estos términos y condiciones será sometida a la jurisdicción exclusiva de los tribunales de ese país.
            </p>
        </div>
    )
}