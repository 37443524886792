import React, { useState } from 'react'

export const ModalFactura = ({ onSubmit }) => {
    const [docfact, setDocFact] = useState('')
    const [nameExtencion, setNameExtencion] = useState()
    const [disablebutton, setDisablebutton] = useState(false)

    const handleInputChange = (e) => {
        const imagen = e.target.files[0]
        setDocFact(imagen);
        const fileName = e.target.files[0].name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        setNameExtencion(fileExtension);
        setDisablebutton(true)
    };
   
    const handleSubmit = () => {
        onSubmit(docfact, nameExtencion);
        setDocFact('');
        setDisablebutton(false)
    };

    return (
        <div className="modal fade" id="modalFactura" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Cargar Factura</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <input type="file" accept="image/jpg" capture='environment' onChange={handleInputChange}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        {disablebutton && 
                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Guardar Factura</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
