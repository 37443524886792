import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';


export const ResponsiveTable = (data) => {

    const [busqueda, setBusqueda] = useState('');
    const navigate = useNavigate();
    
    const accessorsFiltrados = [
        "Fecha",
        "iddoc",
        "Cliente",
        "CuitDni_cli",
        "Num_Os",
        'Articulo',
        'Garantia',
        'Estado'
        // Agrega otros accessors aquí
      ];
      
      const nombresClaves = {
        "Fecha":"Fecha",
        "iddoc": "N° Orden",
        "Cliente": "Cliente",
        "CuitDni_cli": "Cuit/Dni",
        "Num_Os": "Num. Os taller",
        'Articulo': "Articulo",
        'Garantia': "Garantia",
        'Estado': "Estado"
        // Agrega otros nombres aquí
      };
      const datosFiltrados = data.data
      .filter((dato) => {
        return accessorsFiltrados.some(accessor => {
          if (dato.hasOwnProperty(accessor)) {
            const valor = dato[accessor].toString().toLowerCase();
            return valor.includes(busqueda.toLowerCase());
          }
          return false;
        });
      })
      .map((dato, index) => {
        const datoFiltrado = {};
        accessorsFiltrados.forEach((accessor) => {
          if (dato.hasOwnProperty(accessor)) {
            if (accessor === "TimeStamp") {
              datoFiltrado[nombresClaves[accessor]] = new Date(dato[accessor] * 1000).toLocaleString().split(',')[0];
            } else if (accessor === "Garantia") {
              datoFiltrado[nombresClaves[accessor]] = dato[accessor] ? "SI" : "NO";
            } else {
              datoFiltrado[nombresClaves[accessor]] = dato[accessor];
            }
          }
        });
        datoFiltrado.id = dato.Id; // Asumiendo que el ID se llama "iddoc"
        return datoFiltrado;
      });
  
    const handleCentral = (id) => {
      navigate(`/ordenes/central/${id}`);
    };
    const handleCerrado = (id) => {
      navigate(`/ordenes/cerrado/${id}`);
    };
  
  
    return (
      <div>
        <input
          style={{ marginBottom: "1rem" }}
          type="text"
          placeholder="Buscar..."
          value={busqueda}
          onChange={(e) => setBusqueda(e.target.value)}
        />
        <table className="table table-striped">
          <tbody>
            {datosFiltrados.map((item, index) => (
              <React.Fragment key={`row-${index}`}>
                
                {Object.entries(item).map(([clave, valor]) => (
                  <tr key={`data-${clave}`}>
                    <td>{clave}</td>
                    <td>{JSON.stringify(valor)}</td>
                  </tr>
                ))}
                <tr>
                <td>cambiar estado :</td>
                <td colSpan="2">
                  <button type="button" className="btn btn-outline-primary m-2" onClick={() =>handleCentral(item.id)}>A central</button>
                  <button type="button" className="btn btn-outline-primary m-2" onClick={() =>handleCerrado(item.id)}>A Cerrado</button>
                </td>
              </tr>
                {index < datosFiltrados.length - 1 && (
                  <tr key={`separator-${index}`}>
                    <td colSpan="2">----------------------------------------</td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  }