import { configureStore } from '@reduxjs/toolkit'
import ordersInfoReducer from './ordersInfo/ordersInfoSlices'
import repuestosInfoReducer from './repuestosInfo/repuestosInfoSlices'
import userInfoReducer from './userInfo/userInfoSlices'
import newOrderReducer from './ordersInfo/newOrderSlices'
import Feedbackreducer from './ordersInfo/FeedbackSlices'
import RowInforeducer from './rowInfo/rowInfoSlice'
import ManoDeObrareducer from './ordersInfo/ManoObraSlices'

export const store = configureStore({
    reducer: {
        ordersInfo: ordersInfoReducer,
        repuestosInfo: repuestosInfoReducer,
        userInfo: userInfoReducer,
        newOrder: newOrderReducer,
        feedback: Feedbackreducer,
        rowInfo: RowInforeducer,
        manodeobra: ManoDeObrareducer
    }
})