import React, {useEffect, useState, useRef} from 'react'
import {Actualizar, GetOrder} from './dbfirebase'
import { useMatch, useNavigate } from 'react-router-dom';
import { useAuth } from "../context/authcontext";

export const ModalCancelar = () => {

    const match = useMatch('/ordenes/cancelar/:id')
    const id = match.params.id
    const navigate = useNavigate();
    const {user} = useAuth();
    const[motivo, setMotivo] = useState()

    const textarea = useRef(null);
        

    const dataId = async () =>{
        const textareaValue = textarea.current.value;
        const newstate = await Actualizar('Cancelado', {Fecha_Despachado: new Date().toLocaleString(), usuario: user.email},textareaValue, id);
        navigate('/ordenes')
        // Limpia el contenido del textarea
        textarea.current.value = "";
    }

    
    return (
        <div>
            <div>
                <div style={{ overflow: "auto",height:'23rem'}} >
                    <h2>Orden de servicio</h2>
                    <p>Motivo de cancelacion</p>
                        <div className=' col-12 d-flex justify-content-center'>
                            <textarea rows='5' style={{marginTop:'20px', width:'100%'}} name='motivo' ref={textarea}>
                                
                            </textarea>
                        </div>  
                        <div className=' col-12 d-flex justify-content-end'>    
                            <button className="btn btn-secondary" style={{height:'3rem', width:'7rem',marginTop:'20px' }} onClick={()=>{dataId()}}>
                                Confirmar
                            </button>
                        </div>
                </div>
            </div>
        </div>
    );
  };