import { createContext, useContext } from 'react';
import { GetPerfil } from '../components/dbfirebase'
import { useAuth } from "../context/authcontext";
import { collection, doc } from 'firebase/firestore';
import { db } from '../components/firebase';

// Creamos un contexto 
export const GeneralContext = createContext();

//Funcion almacena los datos que retorne el contexto
export const useGeneral = () => {
    const context = useContext(GeneralContext)
    return context
};

//Funcion que proveera al contexto de los datos de logueo
export function GeneralProvider({ children, logged }) {
    const { user } = useAuth();
    let userData = {};
    let generateSecretKey = () => {};

    if (logged) {
        
        userData = GetPerfil(user ? user.uid : '');

        generateSecretKey = (docName) => {
            const docRef = doc(collection(db, docName));
            const id = docRef.id;
            let number = [];
            const charArray = id.substring(5, 10).split("");
            charArray.map((char) => {
                let numb = char.charCodeAt();
                number.push(numb);
            });
            return number.join('').toString();
        };
    }

    return (
        <GeneralContext.Provider value={{ userData, generateSecretKey }}>
            {children}
        </GeneralContext.Provider>
    );

}