import React, {  useState, useEffect, useRef } from 'react'
import { Actualizar } from './dbfirebase'
import { Link } from 'react-router-dom';
import { ModalFinalizado } from './ModalFinalizado'
import { ModalFirmar} from './ModalFirmar'
import { ModalDespachado } from './Modaldespacho'
import { ModalDiagnosticado } from './Modaldiagnosticado'
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../context/authcontext";
import { useSelector } from 'react-redux';



export const actualizar = async (estado, Actualizado, id, setUpdate) => {

    //El string vacio es para evitar enviar el id como campo al ejecutar el motivo
    const newstate = await Actualizar(estado, Actualizado,'', id)    
    setUpdate(true)    
}

export const columnsOrdenes = (setUpdate) => [
    
    {
        Header: "Fecha",
        accessor: "TimeStamp",
        Cell: ({ value }) => { return (new Date(value * 1000).toLocaleString().split(',')[0]) }
    },
    {
        Header: "N° Orden",
        accessor: "iddoc"
    },
    {
        Header: "Cliente",
        accessor: "Cliente"
    },
    {
        Header: "Cuit/Dni",
        accessor: "CuitDni_cli"
    },
    {
        Header: "Num. Os taller",
        accessor: "Num_Os"
    },
    {
        Header: "Articulo",
        accessor: "Articulo"
    },
    {
        Header: "Garanatia",
        accessor: "Garantia",
        Cell: ({ value }) => { if (value) { return ('SI') } else { return ('NO') } }
    },
  
    {
        Header: "Estado",
        accessor: "Estado",
        Cell: ({ row }) => {
            const {user} = useAuth();
            const [rowd, setRow] = useState() 
            const userdatos = useSelector(state => state.userInfo);
            
            //-----------------------------------------------------------------
           //userdato.id == row.original.uuid || row.original.estado === envio a {mostrar solo un buton que diga "en envio a central"}
            
            const navigate = useNavigate();
            
            const handleRowClick = (ruta,id) => {
                navigate(`/ordenes/${ruta}/${id}`);
             
              };
            
            const handleRowClicker = (ruta,id) => {
                navigate(`/ordenes/central/${id}`);
             
              };
            
           
            // setDataClose({handleRowClick})
           
            return (
                <div>
        {/* {userdatos.UUID === row.original.UUID && (row.original.Estado === 'Envio a central' || row.original.Estado === 'Recibido') ? ( */}
        {userdatos.UUID === row.original.UUID && (row.original?.EnviadoAGoldmund === true) ? ( 
            // <button className="dropdown-item" onClick={() => handleRowClicker('En central', row.original.Id)}>Envio a central</button>
            <div>  
                <p>En goldmund: ({row.original.Estado})</p>
            </div>
        ) : (
            <div className="btn-group dropup">
                <button id="Estado" style={{ border: "none", backgroundColor: row.original.Estado === 'Finalizado' ? '#CCF998' : row.original.Estado === 'Reparado' ? '#ffd966' : 'white', color: 'black' }} type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setRow(row.original.Id)}>
                    {row.original.Estado}
                </button>
                <div className="dropdown-menu dropdown-menu-tabla" data-popper-placement="let-start">
                    {row.original.Garantia ? (
                        <Link to={`/repuestos/${row.original.Id}`} style={{ textDecoration: "none" }}>
                            <button className="dropdown-item" onClick={() => actualizar('Repuestos', { Fecha_repuestos: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>Solicitar repuestos</button>
                        </Link>
                    ) : null}
                    <button className="dropdown-item" onClick={() => actualizar('Reparando', { Fecha_reparando: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>En proceso de reparacion</button>
                    <button className="dropdown-item" onClick={() => actualizar('Recibido', { Fecha_reparando: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>Recibido</button>
                    <button className="dropdown-item" onClick={() => actualizar('Pendiente', { Fecha_pendiente: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>Pendiente</button>
                    {userdatos && userdatos?.Permiso == "Admin"?
                        <button className="dropdown-item" onClick={() => actualizar('Facturado', { Fecha_Facturado: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>Facturado</button>
                    : ""}
                    <button className="dropdown-item" onClick={() => handleRowClicker('En central', row.original.Id)}>Se envia a service central</button>
                    {!row.original.Garantia ? (
                        <button className="dropdown-item" onClick={() => actualizar('PresAceptado', { Fecha_presAceptado: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>Presupuesto Aceptado</button>
                    ) : null}
                    <button className="dropdown-item" onClick={() => actualizar('Despacho', { Fecha_enDespacho: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>En despacho</button>
                    <button className="dropdown-item" onClick={() => actualizar('Retirar', { Fecha_paraRetirar: new Date().toLocaleString(), usuario: user.email }, row.original.Id, setUpdate)}>Listo para retirar</button>
                    <button onClick={() => handleRowClick('despacho', row.original.Id)} className="dropdown-item">Despachado</button>
                    <button onClick={() => handleRowClick('cancelar', row.original.Id)} className="dropdown-item">Cancelar</button>
                    {!row.original.Garantia ? (
                        <button onClick={() => handleRowClick('diagnosticado', row.original.Id)} className="dropdown-item">Diagnosticado</button>
                    ) : null}
                    {!row.original.Garantia ? (
                        <button onClick={() => handleRowClick('presupuestado', row.original.Id)} className="dropdown-item">Presupuestado</button>
                    ) : null}
                    {!row.original.Garantia ? (
                        <button onClick={() => handleRowClick('presupuestorechazado', row.original.Id)} className="dropdown-item">Presupuesto rechazado</button>
                    ) : null}
                    <button onClick={() => handleRowClick('finalizado', row.original.Id)} className="dropdown-item">Reparado</button>
                    <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#Modal2">Firmar Retiro</button>
                    <button onClick={() => handleRowClick('cerrado', row.original.Id)} className="dropdown-item">Cerrar</button>
                </div>
                <ModalFirmar rowdata={rowd} setUpdate={setUpdate} />
            </div>
        )}
    </div>
            )  
        } 
    },
   
]

export const columnsOrdenes2 = () => [

    {
        Header: "Fecha",
        accessor: "TimeStamp",
    },
    {
        Header: "N° Orden",
        accessor: "iddoc"
    },
    {
        Header: "Cliente",
        accessor: "Cliente"
    },
    {
        Header: "Cuit/Dni",
        accessor: "CuitDni_cli"
    },
    {
        Header: "Num. Os taller",
        accessor: "Num_Os"
    },
    {
        Header: "Articulo",
        accessor: "Articulo"
    },
    {
        Header: "Garantia",
        accessor: "Garantia",
    },
    
    {
        Header: "Estado",
        accessor: "Estado",        
    } 
    
   
]
export const formatCurrency = (value) => {
    try {
      const formattedValue = "$" + value.toFixed(2);
      return formattedValue;
    } catch (error) {
    
      return "$0.00"; // Valor predeterminado en caso de error
    }
  };

export const OrdenesReparadas = [
    {
        Header: "Fecha",
        accessor: "TimeStamp",
        Cell: ({ value }) => { return (new Date(value * 1000).toLocaleString().split(',')[0]) }
    },
    {
        Header: "N° Orden",
        accessor: "iddoc"
    },
    {
        Header: "Cliente",
        accessor: "Cliente"
    },
    {
        Header: "Num. Os taller",
        accessor: "Num_Os"
    },
    {
        Header: "Articulo",
        accessor: "Articulo"
    },
    {
        Header: "Garantia",
        accessor: "Garantia",
        Cell: ({ value }) => { if (value) { return ('SI') } else { return ('NO') } }
    },
    {
        Header: "Categoria",
        accessor: "Categoria"
    },
    {
        Header: "Sub-categoria",
        accessor: "Tipo"
    },
    {
        Header:"$ Costo",
        accessor:"ManoDeObra",
        Cell: ({ value }) => formatCurrency(value),
    },
]


export const columnsRepuestos = [
    // {
    //     Header: "Articulo",
    //     accessor: "Articulo",
    // },
    // {
    //     Header: "Categoria",
    //     accessor: "Categoria",
    // },
    // {
    //     Header: "Tipo",
    //     accessor: "Tipo",
    // },
    // {
    //     Header: "Mano de obra",
    //     accessor: "mdo",
    // },
    {
        Header: "Repuesto",
        accessor: "Articulo",
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion",
    },
    {
        Header: "Precio Publico",
        accessor: "PrecioPublico",
        Cell: ({ value }) => formatCurrency(value),
    },
    {
        Header: "Precio Taller",
        accessor: "PrecioTaller",
        Cell: ({ value }) => formatCurrency(value),
    },
    // {
    //     Header: "Ultima Actualizacion",
    //     accessor: "Ult_Act",
    // },

]


export const columnsRepuestosGarantia = [
    // {
    //     Header: "Articulo",
    //     accessor: "Articulo",
    // },
    // {
    //     Header: "Categoria",
    //     accessor: "Categoria",
    // },
    // {
    //     Header: "Tipo",
    //     accessor: "Tipo",
    // },
    // {
    //     Header: "Mano de obra",
    //     accessor: "mdo",
    // },
    {
        Header: "Repuesto",
        accessor: "Articulo",
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion",
    },
    // {
    //     Header: "Precio Publico",
    //     accessor: "PrecioPublico",
    //     //Cell: ({ value }) => formatCurrency(value),
    // },
    // {
    //     Header: "Precio Taller",
    //     accessor: "PrecioTaller",
    //     //Cell: ({ value }) => formatCurrency(value),
    // },
    // {
    //     Header: "Ultima Actualizacion",
    //     accessor: "Ult_Act",
    // },

]

export const columnsRepuestosConfirmar = [
    {
        Header: "Fecha",
        accessor: "Fecha",
    },
    {
        Header: "Repuesto",
        accessor: "Articulo"
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion"
    },
    {
        Header: "Precio Publico",
        accessor: "PrecioPublico",
    },
    {
        Header: "Precio Taller",
        accessor: "PrecioTaller",
    },
    {
        Header: "Cantidad",
        accessor: "cantidad",
    }
]
export const columnsPedidos = [
    
    {
        Header: "Fecha",
        accessor: "Ult_Act",
        Cell: ({ value }) => { return (new Date(value).toLocaleString().split(',')[0])}
    },
    {
        Header: "n° Orden",
        accessor: "iddoc",
    },
    {
        Header: "Cantidad",
        accessor: "cantidad",
    },
    {
        Header: "Articulo",
        accessor: "Articulo",
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion",
    },
    {
        Header: "Cliente",
        accessor: "Cliente",
    },
    {
        Header: "Falla",
        accessor: "Falla"
    },
    {
        Header: "Garantia",
        accessor: "Garantia",
        Cell: ({ value }) => { if (value) { return ('SI') } else { return ('NO') } }
    },
    // {
    //     Header: "Precio Publico",
    //     accessor: "PrecioPublico",
    //     Cell: ({ value }) => formatCurrency(value),
    // },
    // {
    //     Header: "Precio Taller",
    //     accessor: "PrecioTaller",
    //     Cell: ({ value }) => formatCurrency(value),
    // },
    // {
    //     Header: "Precio Publico",
    //     accessor: "PrecioPublico",
    //     Cell: ({ value }) => formatCurrency(value),
    // },
    // {
    //     Header: "Precio Taller",
    //     accessor: "PrecioTaller",
    //     Cell: ({ value }) => formatCurrency(value),
    // },
    {
        Header: "Estado",
        accessor: "UltEstado",
        Cell: ({ value }) => {
            let style = {};

            if (typeof value === "string") {
            const estado = value.toLowerCase();

            // Establecer estilos condicionales basados en el valor del estado
            if (estado === "pendiente") {
                    style = { backgroundColor: "yellow" };
                } else if (estado === "despacho") {
                    style = { backgroundColor: "blue", color: "white" };
                } else if (estado === "transporte") {
                    style = { backgroundColor: "green", color: "white" };
                } else if (estado === "sinstock") {
                    style = { backgroundColor: "red", color: "white" };
                }
            }
            return <div style={style}>{value}</div>;
        }
    }
]

export const columnsRepuestosFacturar = [
    {
        Header: "Fecha",
        accessor: "Fecha",
    },
    {
        Header: "Repuesto",
        accessor: "Articulo"
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion"
    },
    {
        Header: "Precio",
        accessor: "PrecioTaller",
    },
    {
        Header: "Cantidad",
        accessor: "cantidad",
    }
]