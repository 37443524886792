import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    Email: '',
    UUID: '',
    Fecha_repuestos: "",
    NumFactura: "",
    Permiso: '',
    iddoc: "",
    Taller: '',
    Comentarios: "",
    RazonSocialCli: "",
    Telefono_cli: "",
    Fecha_finalizado: "",
    Id: "",
    Articulo: "",
    Fecha_reparando: "",
    Garantia: '',
    Direccion: '',
    Fecha_encentral: "",
    Titular: '',
    Cuit: '',
    CuitDni_cli: "",
    Estado: "",
    Email_cli: "",
    Telefono: '',
    FechaCompra: "",
    Fecha: '',
    TimeStamp: '',
    Cliente: '',
    Mdo: "-",
    Categoria: "",
    Domicilio_cli: "",
    Tipo: "",
    Desperfecto: "",
    MotivoCierre: [],
    Repuestos: [],
    Actualizado: [],
}

export const newOrderSlice = createSlice({
    name: 'newOrder',
    initialState,
    reducers: {
        updateSparepart: (state, action) => {
            // toma los datos de action
            const { index, updatedSparepart } = action.payload;

            // copia la lista de repuestos
            const updatedRepuestos = [...state.Repuestos];

            // actualiza el repuesto con el índice proporcionado
            updatedRepuestos[index] = updatedSparepart;

            // devuelve el estado actualizado
            return { ...state, Repuestos: updatedRepuestos };
        },

        addNewOrder: (state, action) => {
            return { ...state, ...action.payload }
        },
        clearNewOrder: (state) => {
            return initialState
        },
        addSparepart: (state, action) => {
            //toma los datos de action
            const { id, Articulo, cantidad, PrecioPublico, PrecioTaller, Descripcion, Sku, UltEstado, Fecha, Ult_Act } = action.payload
            
            //revisa si existe el repuesto con el mismo codigo y sku del producto 
            const existingSparepartIndex = state.Repuestos.findIndex((item) => item.Articulo === Articulo && item.Sku === Sku)

            //si  el repuesto ya fue agregado le cambia la cantidad
            if (existingSparepartIndex >= 0) {
                //copia la lista
                const updatedRepuestos = [...state.Repuestos]

                if (cantidad === 0) {
                    //si la cantidad es 0, elimina el elemento
                    updatedRepuestos.splice(existingSparepartIndex,1)
                } else {
                                    //actualiza el elemento con el indice
                updatedRepuestos[existingSparepartIndex] = { 
                    id, Articulo, cantidad, PrecioPublico, PrecioTaller, Descripcion, Sku, UltEstado:"Pendiente", Fecha: new Date().toLocaleString().split(',')[0], Ult_Act: new Date().getTime(),
                }
                }

               //devuelve el estado actualizado
                return { ...state, Repuestos: updatedRepuestos }
            } else {
                //devuelve el estado con un elemento agregado
                return { 
                    ...state, 
                    Repuestos: [
                        ...state.Repuestos, {
                            id, Articulo, cantidad, PrecioPublico, PrecioTaller, Descripcion, Sku, UltEstado:"Pendiente", Fecha: new Date().toLocaleString().split(',')[0],Ult_Act: new Date().getTime(),
                            }
                    ] 
                }
            }
        }
    }
})
export const { addNewOrder, clearNewOrder, addSparepart, updateSparepart } = newOrderSlice.actions
export default newOrderSlice.reducer