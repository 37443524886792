import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ExportOrderTable } from './exporOrderTable';

export const ModalTable = () => {
  const ordersData = useSelector(state => state.ordersInfo);
  const tableRe = useRef();
  const [tableReady, setTableReady] = useState(false);
 
 
  const table = () => {
    return (
      <table ref={tableRe} style={{ display: 'none' }}>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>NºOrden</th>
            <th>Cliente</th>
            <th>Cuit/Dni</th>
            <th>Num. Os Taller</th>
            <th>Articulo</th>
            <th>Garantia</th>
            <th> Estado</th>  
              
          </tr>
        </thead>
        <tbody>
          {ordersData.map(order => (
            <tr key={order.iddoc}>
              <td>{order.Fecha}</td>
              <td>{order.iddoc}</td>
              <td>{order.Cliente}</td>
              <td>{order.Cuit}</td>
              <td>{order.Num_Os}</td>
              <td>{order.Articulo}</td>
              <td>{order.Garantia ? 'SI' : 'NO'}</td>
              <td>{order.Estado}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    if (tableRe.current) {
      setTableReady(true);
    }
  }, [tableRe]); // Change here
  
  return (
  <>

    {table()}   
      <div className="modal fade" id="modalTable" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirmar descarga
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p> ¿Deseas exportar la tabla a Excel?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cerrar
              </button>

              {tableReady && <ExportOrderTable tablereferencia={tableRe} />}
            
            </div>
          </div>
        </div>  
      </div>
    </>
  );
};