import React, { useState } from 'react'
import UsuarioService from '../images/UsuarioService.PNG'
import '../css/styles.css';
import { useAuth } from "../context/authcontext";
import { useGeneral } from '../context/generalContext'
import { useDispatch } from 'react-redux';
import { addUserInfo } from '../store/userInfo/userInfoSlices';
import { useEffect } from 'react';
import {Ayuda} from './Help'
import { updatePassword } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
import eyeIco from '../images/eyeduotone.png';


export const Micuenta = () => {

    const [userdatos, setUserdatos] = useState({})
    const [nuevacontraseña, setNuevaContraseña] = useState(false)
    const [newpassword, setNewPassword] = useState()
    // estado para la visibilidad del password 
    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");
    

    const { userData } = useGeneral()
    const { user, logout } = useAuth();
    
    
    const dispatch = useDispatch()

    const perfil = async () => {
        const dato = await userData
        setUserdatos(dato)
        kick(dato)
        //dispatch(addUserInfo(dato))
    }

    const kick = async(dato) => {
        
        if (dato.App === "God" || dato.App === 'Service'){
            
            //await logout()
        }else{
           
            await logout()
        }
    }

    useEffect(() => {
        perfil()
    }, [])
    
    //funcion para cambiar visibilidad de la contraseña
    const togglePassword = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
        } else {
            setPasswordType1("password")
        }
    }

    const togglePassword2 = () => {
        if (passwordType2 === "password") {
            setPasswordType2("text")
        } else {
            setPasswordType2("password")
        }
    }
      
    const isNotificationSupported = "Notification" in window;

    const requestNotificationPermission = async () => {
        if ("Notification" in window) {
          try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
              console.log("Permiso concedido para mostrar notificaciones.");
            } else if (permission === "denied") {
              console.log("Permiso denegado para mostrar notificaciones.");
            }
          } catch (error) {
            console.error("Error al solicitar permiso para notificaciones:", error);
          }
        }
    };
    
    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      };
    
    useEffect(() => {
        if (isNotificationSupported && !isMobileDevice()) {
          requestNotificationPermission();
        }
    }, []);

    //Funcion que captura los datos de los inputs y los coloca en el hook
    const handlechange = (e) => {
        const { name, value } = e.target;
        setNewPassword((prevState) => ({ ...prevState, [name]: value}));
    }
    
    const changepassword = async (newPassword) =>{
        let messageOk = "Contraseña actualizada con exito."
        let messageError = "Contraseña no actualizada."
        try {
            if (newPassword.newpassword === newPassword.newpasswordCopy && newPassword.newpassword !== ''){
                
                await updatePassword(user, newPassword.newpassword);
                setNuevaContraseña(false)
                toast.success(messageOk,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                }) 
            } else {
                
                toast.error('Las contraseñas deben ser iguales',{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                }) 
            }
            
        } catch (error) {
            
            toast.error(messageError,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })   
        }
    }

    return (
        <div>
            <div className='row'>
            <ToastContainer />
            <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Mi cuenta</h3>
                <Ayuda section="micuenta"/>
            </div>
            <div className='container'>
                <div className='row d-flex justify-content-center contenedor' style={{ margin: '0 auto' }}>
                    <div className='col-4 d-flex justify-content-center' style={{ margin: '0 auto' }}>
                        <img src={user ? user.photoURL : UsuarioService}></img>
                    </div>

                    <div className='col-4 d-flex justify-content-center' style={{ margin: '0 auto' }}>
                        <table className="d-flex justify-content-end" style={{ borderSpacing: '10px 20px', borderCollapse: 'separate' }}>
                            <tbody>
                                <tr>
                                    <td style={{ height: '2rem', width: '10rem' }}>Responsable:</td>
                                    <td style={{ height: '2rem', width: '18rem', backgroundColor: '#D9D9D9' }}>{userdatos.Titular}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '2rem', width: '10rem' }}>Razon Social:</td>
                                    <td style={{ height: '2rem', width: '18rem', backgroundColor: '#D9D9D9' }}>{userdatos.Taller}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '2rem', width: '10rem' }}>Telefono:</td>
                                    <td style={{ height: '2rem', width: '18rem', backgroundColor: '#D9D9D9' }}>{userdatos.Telefono}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '2rem', width: '10rem' }}>Cuit/Cuil:</td>
                                    <td style={{ height: '2rem', width: '18rem', backgroundColor: '#D9D9D9' }}>{userdatos.Cuit}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '2rem', width: '10rem' }}>Dirección:</td>
                                    <td style={{ height: '2rem', width: '18rem', backgroundColor: '#D9D9D9' }}>
                                        {/* {userdatos.Direccion} */}
                                        {`${userdatos.Direccion} - ${userdatos.Localidad}, ${userdatos.Provincia}`}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ height: '2rem', width: '10rem' }}>Mail:</td>
                                    <td style={{ height: '2rem', width: '18rem', backgroundColor: '#D9D9D9' }}>{userdatos.Email}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-4 d-flex justify-content-center' style={{ margin: '0 auto' }}>
                        <div className='row'>
                            <button className="btn btn-primary" style={{ height: '3rem' }} onClick={()=>{setNuevaContraseña(true)}}>Cambiar contraseña</button>
                        {nuevacontraseña &&
                             <div >
                            <div className="passwordContainer mb-4 position-relative">
                                <label style={{marginTop: '25px'}}>Nueva contraseña</label>
                                <input 
                                    type = {passwordType1}
                                    name = "newpassword"
                                    onChange = {handlechange} 
                                    className = "form-control"
                                    placeholder = "Nueva contraseña"
                                    id = "password"
                                    style={{marginTop: '25px'}}
                                />
                                <img className="eyeIcon mt-3"
                                    onClick={togglePassword}
                                    src={eyeIco}
                                    alt="o"/>
                            </div>
                            <div className="passwordContainer mb-4 position-relative">
                                <label style={{marginTop: '25px'}}>Repetir contraseña</label>
                                <input 
                                    type = {passwordType2}
                                    name = "newpasswordCopy"
                                    onChange = {handlechange} 
                                    className = "form-control"
                                    placeholder = "Repetir contraseña"
                                    id = "password"
                                    style={{marginTop: '25px'}}
                                />
                                <img className="eyeIcon mt-3"
                                    onClick={togglePassword2}
                                    src={eyeIco}
                                    alt="o"/>
                            </div>
                            <button className="btn btn-success" style={{ height: '3rem', width: '10rem' }} onClick={()=>{changepassword(newpassword)}}>Cambiar</button>
                        </div>
                        } 
                        </div>
                    </div>
                </div>
            </div>
            {/* Dashboard, facturacion, estados de cuenta...  */}
            {/* <div className='row' style={{marginTop: '25px'}}>
                <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Estado de cuenta:</h3>
                <div className='container'>
                    {reparados && 
                        <Table showDelete={true} listaOrdenes={reparados} source={"orden"} rows={reparados.length} settingcolumns={OrdenesReparadas} globalfilter={true} statefilter={false} exporttable={false} showRequest={true}/>
                    }
                </div>
            </div> */}

            
        </div>
    )
}