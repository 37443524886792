import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    Taller: '',
    Direccion: '',
    Cuit: '',
    Telefono: '',
    Titular: '',
    Email: '',
    UUID: '',
    Permiso: '',
    Id: ''
}


export const userInfoSlice = createSlice({

    name: 'userInfo',
    initialState,
    reducers: {
        addUserInfo: (state, action) => {
            return action.payload
        },
    }
})
export const { addUserInfo } = userInfoSlice.actions
export default userInfoSlice.reducer