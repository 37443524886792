import React, { useState, useEffect } from 'react'
import nuevodocumento from '../images/nuevo-documento.png'
import { Table } from './table'
import { columnsOrdenes } from './columns';
import { GetOrders } from './dbfirebase'
import { Link, useNavigate } from "react-router-dom";
import { useGeneral } from '../context/generalContext'
import '../css/styles.css';
import { useSelector } from 'react-redux';
import {TarjetaAcordeon} from './tarjetas'
import {Ayuda} from './Help'
import {ResponsiveTable} from './ResponsiveTable'
import { ModalTable } from './ModalTable';
import excel from '../images/excel.png'

export const Ordenes = () => {

    const navigate = useNavigate();

    const [setupdate, setUpdate] = useState(false)
    const [data, setData] = useState('')
    const [restaurar, setRestaurar] = useState(false)
    // const [ordersData, setOrdersData] = useState([]);

    const userdatos = useSelector(state => state.userInfo)
    const ordersData = useSelector(state => state.ordersInfo)
    const feedback = useSelector(state => state.feedback)
    
    const Nuevaorden = () => {
        navigate('/nuevaorden')
    }

    const CalculateDate = (date, days) => {
        date.setDate(date.getDate() - days)
        return date
    }


    // // Traemos la ultima version si es que hubo modificaciones
    // const actualizarDatos = () => {
    //     const updatedOrdersData = orders.map((item) => {
    //       if (item.Modificaciones && Array.isArray(item.Modificaciones) && item.Modificaciones.length > 0) {
    //         return item.Modificaciones[item.Modificaciones.length - 1];
    //       } else {
    //         return item;
    //       }
    //     });
    //     setOrdersData(updatedOrdersData);
    // };

    // useEffect(()=>{
    //     actualizarDatos()
    // },[orders,feedback])
    
    const hoy = new Date().toLocaleString().split(',')[0]
    const pendingOrders = ordersData && ordersData.filter((value) => value.Estado !== 'Finalizado')
    const UnchangedOrders = ordersData && ordersData.filter((value) => value.Estado === 'Pendiente')
    const qtyOfTodayOrders = ordersData && pendingOrders.filter((value) => new Date(value.TimeStamp * 1000).toLocaleString().split(',')[0] === hoy).length

    // saca los pedidos de repuestos independientes
    const filterOrders = () => {
        return ordersData.filter(ord => ord.Cliente !== ord.Taller)
    }

    const nofilteredData = () => {
        setData(filterOrders())
        setRestaurar(false)
    }
    
    useEffect(()=>{
        nofilteredData()
    },[ordersData])

    const filterTodayOrders = () => {
        setData(pendingOrders.filter((value) => new Date(value.TimeStamp * 1000).toLocaleString().split(',')[0] === hoy))
        setRestaurar(true)
    }
    
    const fiveDaysAgo = new Date();
    let daysToSubtract01 = 3;
    let i = 0;

    while (i < daysToSubtract01) {
        fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 1);

        const dayOfWeek01 = fiveDaysAgo.getDay();
        if (dayOfWeek01 !== 0 && dayOfWeek01 !== 6) {
            i++;
        }
    }

    const tenDaysAgo = new Date();
    let daysToSubtract02 = 5;
    let j = 0;

    while (j < daysToSubtract02) {
        tenDaysAgo.setDate(tenDaysAgo.getDate() - 1);

        const dayOfWeek02 = tenDaysAgo.getDay();
        if (dayOfWeek02 !== 0 && dayOfWeek02 !== 6) {
            j++;
        }
    }

    const qtyOfOrdsOverFiveDays = ordersData && UnchangedOrders.filter((value) => {
        const orderDate = new Date(value.TimeStamp * 1000);
        const orderTime = orderDate.getTime();
        return orderTime <= fiveDaysAgo.getTime() && orderTime > tenDaysAgo.getTime();
    }).length;

    const filterFiveDaysOrders = () => {
        setData(UnchangedOrders.filter((value) => {
            const orderDate = new Date(value.TimeStamp * 1000);
            const orderTime = orderDate.getTime();
            return orderTime <= fiveDaysAgo.getTime() && orderTime > tenDaysAgo.getTime();
          }))
        setRestaurar(true)
    }

    const qtyOfOrdsOverTenDays = ordersData && UnchangedOrders.filter((value) => {
        const orderDate = new Date(value.TimeStamp * 1000);
        return orderDate.getTime() <= tenDaysAgo.getTime();
    }).length;

    const filterTenDaysOrders = () => {
        setData(UnchangedOrders.filter((value) => {
            const orderDate = new Date(value.TimeStamp * 1000);   
            return orderDate.getTime() <= tenDaysAgo.getTime();
          }))
        setRestaurar(true)
    }

    const filterOrdersToday = () => {
        return filterOrders().filter((value) => new Date(value.TimeStamp * 1000).toLocaleString().split(',')[0] === hoy).length
    }

    const PresupuestoPendiente = ordersData && ordersData.filter((value) => value.Estado === 'Diagnosticado').length

    const filterPresupuestoOrders = () => {
        setData(filterOrders().filter((value) => value.Estado === 'Diagnosticado'))
        setRestaurar(true)
    }
    

    return (
        <div>
            <ModalTable  /> 

            <div className='row'>
                <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Ordenes de servicio</h3>
                <Ayuda section="ordenes"/>
                {(userdatos && (userdatos.App === 'God' || userdatos.App === 'Service' || userdatos.Taller === 'Goldmund (central)')) && (
                            <button
                                className='col-4 d-flex justify-content-end'
                                style={{ margin: '0 auto', border: 'none', backgroundColor: '#ffffff' }}
                                onClick={Nuevaorden}
                            >
                        
                    <div className='container d-flex justify-content-end'>
                        <img src={nuevodocumento} width="60rem" height="60rem" alt='' />
                        <p className="text-center">Nueva Orden</p>
                    </div>
                </button>)}
            </div>
            {/* Indicadores de estado */}
            <div className='row d-flex justify-content-center' style={{ marginTop: '50px' }}>
                <div className="col-4 card text-bg-success shadow-lg mb-3" style={{ width: '10rem', margin: '0 80px' }}  >
                    <div className='card-body' onClick={filterTodayOrders}>
                        {/* <h2 style={{ textAlign: 'center' }}>{qtyOfTodayOrders}</h2> */}
                        <h2 style={{ textAlign: 'center' }}>{filterOrdersToday()}</h2> 
                    </div>
                    <div className="card-footer" style={{ textAlign: 'center' }}>
                        Ordenes del dia
                    </div>
                </div>
                <div className="col-4 card text-bg-warning shadow-lg mb-3" style={{ width: '10rem', margin: '0 80px' }}>
                    <div className='card-body' onClick={filterFiveDaysOrders}>
                        <h2 style={{ textAlign: 'center' }}>{qtyOfOrdsOverFiveDays}</h2>
                    </div>
                    <div className="card-footer" style={{ textAlign: 'center' }}>
                        Pendientes mayores a 3 dias
                    </div>
                </div>
                <div className="col-4 card text-bg-danger shadow-lg mb-3" style={{ width: '10rem', margin: '0 80px' }}>
                    <div className='card-body' onClick={filterTenDaysOrders}>
                        <h2 style={{ textAlign: 'center' }}>{qtyOfOrdsOverTenDays}</h2>
                    </div>
                    <div className="card-footer" style={{ textAlign: 'center' }}>
                        Pendientes mayores a 5 dias
                    </div>
                </div>
                <div className="col-4 card text-bg-primary shadow-lg mb-3" style={{ width: '10rem', margin: '0 80px' }}>
                        <div className='card-body' onClick={filterPresupuestoOrders}>
                            <h2 style={{ textAlign: 'center' }}>{PresupuestoPendiente}</h2>
                        </div>
                        <div className="card-footer" style={{ textAlign: 'center' }}>
                            Ordenes con presupuesto pendientes
                        </div>
                </div>
                {/* Card con los datos de evaluacion */}
                <div className="col-4 card shadow-lg mb-3" style={{width: '30rem', margin:'0 80px', backgroundColor:'white'}}>
                    <div className='card-body m-auto'>
                        <div className="d-flex justify-content-center m-auto" >
                        
                        {feedback !== undefined &&  <p className="clasificacion sm " >
                                    <input id="radio1" type="radio" name="estrellas" value="5" checked={Math.round(feedback) === 5} disabled/>
                                    <label htmlFor='radio1' style={{ fontSize: '50px' }}>★</label>
                                    <input id="radio2" type="radio" name="estrellas" value="4" checked={Math.round(feedback) === 4} disabled/>
                                    <label htmlFor='radio2' style={{ fontSize: '50px' }}>★</label>
                                    <input id="radio3" type="radio" name="estrellas" value="3" checked={Math.round(feedback) === 3} disabled/>
                                    <label htmlFor='radio3' style={{ fontSize: '50px' }}>★</label>
                                    <input id="radio4" type="radio" name="estrellas" value="2" checked={Math.round(feedback) === 2} disabled/>
                                    <label htmlFor='radio4' style={{ fontSize: '50px' }}>★</label>
                                    <input id="radio5" type="radio" name="estrellas" value="1" checked={Math.round(feedback) === 1} disabled/>
                                    <label htmlFor='radio5' style={{ fontSize: '50px' }}>★</label>
                                </p>}
                        </div>
                    </div>
                    <div className="card-footer" style={{textAlign: 'center', fontSize: '20px'}}>
                        Como te valoran tus clientes
                    </div>
                </div>
                
            </div>
            {/* tabla con datos */}
           
            <div className='d-flex justify-content-end'>
                 <button type="button" className="btn btn-outline-secondary"  
                        data-bs-toggle="modal"
                        data-bs-target="#modalTable" 
                        >
                            <img src={excel} width="50rem" height="50rem" />
                        </button>  
                    </div>
           

            <div style={{ marginTop: '50px' }}>
                
                {restaurar && 
                    <button onClick={nofilteredData}  className='col-12 btn btn-outline-success'style={{marginBottom: '50px'}} >Restaurar datos</button>
                }
                <div className="table-container">
                {
                    data
                    &&
                    <Table
                        showDetail={true} showComponents={true} listaOrdenes={data}
                        source={"orden"} settingcolumns={columnsOrdenes(setUpdate)}
                        rows={ordersData.length} globalfilter={true}
                        statefilter={true}  setUpdate={setUpdate} showEdit={true} />
                }
                </div>
                <div className="acordeon-container">
                {
                    data
                    &&
                    <ResponsiveTable data={data}/>
                }
                 </div>
            </div>
        </div>
    )
}