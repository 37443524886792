import React, {useState, useEffect} from 'react'
import {Table} from './table'
import { OrdenesReparadas, columnsRepuestosFacturar } from './columns';
import { useSelector } from 'react-redux';
import { AddFactura, GetFacturas } from './dbfirebase'
import { useGeneral } from '../context/generalContext'
import { doc, collection } from "firebase/firestore";
import { db } from "../components/firebase";
import { ModalFactura } from "./FacturaModal"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../components/firebase";

export const Facturacion = () =>{

    const [ordenesmesactual, setOrdenesMesActual] = useState()
    const [ordenesSeleccionadas, setOrdenesSeleccionadas] = useState([])
    const [repuestosFacturar, setRepuestosFacturar] = useState([])
    const [montoOrden, setMontoOrden] = useState([])
    const [datosFacturar, setDatosFacturar] = useState([])
    const [resumen, setResumen] = useState()
    const [sumaPrecioTaller, setSumaPrecioTaller] = useState()
    const userOrdersData = useSelector(state => state.ordersInfo)
    const ManoDeObraData = useSelector(state => state.manodeobra)  
    const { userData, generateSecretKey } = useGeneral()
    const [secretKey, setSecretKey] = useState()
    const [userdatos, setUserdatos] = useState()
    const [downloadURL, setDownloadURL] = useState([]);
    const [filas, setFilas] = useState()
    const [datofactura, setDatosFactura] = useState()
    const [selectAll, setSelectAll] = useState(false);

    const perfil = async () => {
        const dato = await userData
        setUserdatos(dato)
    }

    setTimeout(perfil, 1000)

    useEffect(() => {
        // Filtrar las órdenes para el mes actual
        const ordenesMesActual = userOrdersData.filter(orden => (orden.Estado === "Cerrado" || orden.Estado === "Finalizado") && orden.Garantia === true);
        const ordenesManoDeObra = ordenesMesActual.map((ordenes)=>({
            ...ordenes,
            ManoDeObra: (ordenes.ManoDeObra !== 0 && ordenes.ManoDeObra != null) ? ordenes.ManoDeObra : costomasreciente(ManoDeObraData, ordenes.Tipo, ordenes.TimeStamp)
        }))
        
        setOrdenesMesActual(ordenesManoDeObra)
        
        // Filtrar las órdenes para el mes actual
        const repuestosAFacturar = userOrdersData.filter(orden => orden.Garantia === false && orden.Repuestos.length > 0)
        .flatMap(orden => orden.Repuestos);

        // Actualizar repuestos a facturar
        setRepuestosFacturar(repuestosAFacturar.filter(objeto => objeto.hasOwnProperty('id')));

    },[userOrdersData, ManoDeObraData])
    
    useEffect(() =>{
        // Sumar los valores de PrecioTaller
        if (repuestosFacturar[0]){
            let suma = 0;
            repuestosFacturar.forEach(objeto => {
                if (typeof objeto.PrecioTaller === 'number') {
                    suma += objeto.PrecioTaller;
                }
            });
            setSumaPrecioTaller(suma);
        }
    },[repuestosFacturar])
    
    useEffect(() => {
        const montoOrdenMesActual = ordenesSeleccionadas.reduce((total, orden) => {
            const manoDeObra = orden?.ManoDeObra || 0;
            return total + (isNaN(manoDeObra) ? 0 : manoDeObra);
        }, 0);

        // Crear un conjunto para almacenar los iddoc únicos que deben eliminarse
        const iddocAEliminar = new Set();
        let datosFiltrados = [];
        const contadorIndices = {};

        for (const item of ordenesSeleccionadas) {
            let nuevoItem = { ...item };
                if (!contadorIndices[item.iddoc]) {
                    contadorIndices[item.iddoc] = 1;
                } else {
                    contadorIndices[item.iddoc]++;
                }
                nuevoItem.indiceSecuencial = contadorIndices[item.iddoc];
            if (!item.delete) {
                datosFiltrados.push(nuevoItem);
            } else {
                iddocAEliminar.add({ iddoc: nuevoItem.iddoc, indiceSecuencial: nuevoItem.indiceSecuencial -1 });
            }
        }

        // Eliminar elementos marcados para eliminar
        for (const { iddoc, indiceSecuencial } of iddocAEliminar) {
            if (indiceSecuencial){
                datosFiltrados = datosFiltrados.filter(item =>  item.indiceSecuencial+item.iddoc !== indiceSecuencial+iddoc );
            }
        }

        setDatosFacturar(prev => [datosFiltrados]);
        setMontoOrden(montoOrdenMesActual);
    },[ordenesSeleccionadas]);

    useEffect(() =>{
        setSecretKey(generateSecretKey('Ordenes'))
    },[])

    const resumir = () => {
        // Utilizamos el método map para extraer los valores de iddoc de cada objeto
        const listaIdDoc = datosFacturar[0].map(objeto => objeto.iddoc);
        // Usamos un Set para obtener los iddoc únicos
        const uniqueIdDocs = new Set(listaIdDoc);
        // Filtramos las órdenes para que solo incluyan aquellos cuyo iddoc esté en uniqueIdDocs
        const facturar = ordenesmesactual.filter(item => uniqueIdDocs.has(item.iddoc));
        // Mostramos la lista de iddoc en la consola
        setResumen(facturar);
    };
    
    const facturar = async(docFact, nameExtencion) =>{
        const docRef = doc(collection(db, 'Facturaciones'))
        const factura = { ...userdatos, ...{iddoc: secretKey, Ordenes: resumen, Fecha: new Date(), Total: montoOrden, Estado:"Pendiente"}}
        // La prueba de inexistencia es prueba de existencia
        try{
            await uploadImage(docFact, `FacturasCierreOrdenes/${secretKey}.${nameExtencion}`);
            await AddFactura(docRef, factura)
            setResumen('')
        }catch(error){
            console.log(error)
        }
    }

    const uploadImage = (image, name) => {
        if (image && name) {
            // Obtener la extensión del archivo desde el nombre
            const fileExtension = name.split('.').pop().toLowerCase();
            
            // Lista de extensiones permitidas
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'jfif'];
    
            // Verificar si la extensión es permitida
            if (allowedExtensions.includes(fileExtension)) {
                const storageRef = ref(storage, name);
                const metadata = {
                    contentType: `application/${fileExtension}`,
                };
    
                uploadBytes(storageRef, image, metadata)
                    .then(async(snapshot) => {
                        // Obtener la URL de descarga de la imagen
                        const url = await getDownloadURL(storageRef);
                        setDownloadURL(prevURLs => [...prevURLs, url]);
                        return true
                    })
                    .catch((error) => {
                        console.error('Error al subir la imagen:', error);
                    });
            } else {
                console.warn('Extensión de archivo no permitida. La imagen no se ha subido.');
            }
        } else {
            console.warn('Imagen o nombre de archivo no proporcionados. La imagen no se ha subido.');
        }
    };

    const getfactura = async(userdatos) => {
        const fact = await GetFacturas(userdatos)
        setDatosFactura(fact)
    }

    
    useEffect(()=>{
        if (userdatos){
            getfactura(userdatos)
        }
    },[userdatos])

    const costomasreciente = (data, subcategoria, fecha) => {
        // const fechaActual = Math.floor(new Date().getTime() / 1000);
        const fechaActual = fecha
        let fechaMasCercana = Number.MAX_SAFE_INTEGER;
        let valoresCampoMasCercano = null;
        let diferencias = {};
        try {
            data.forEach((obj) => {
                // Suponiendo que `obj` es el objeto con las fechas como claves
                // let diferencias = {};
                Object.keys(obj).forEach((clave) => {
                    if (clave !== 'Fecha') {
                        const [dia, mes, anio] = clave.split(/[/\-]/) //clave.split('/'); // Modificar para usar - tambien
                        const fechaEnSegundos = new Date(anio, mes - 1, dia).getTime() / 1000;
                        if(fechaEnSegundos < fechaActual){
                            const diferenciaAbsoluta = Math.abs(fechaActual - fechaEnSegundos);
                       
                            diferencias[clave] = diferenciaAbsoluta;

                            // Encontrar la clave con la menor diferencia absoluta
                            let claveMasCercana = diferencias?Object.keys(diferencias).reduce((a, b) => diferencias[a] < diferencias[b] ? a : b):""
                            valoresCampoMasCercano = obj[claveMasCercana];
                        }
                    }
                });
            });
            // Retorna los valores del campo más cercano
            return valoresCampoMasCercano ? Number(valoresCampoMasCercano[0][subcategoria]) : 0;
        } catch (error) {
            console.log(error);
            return '';
        }
    };

    useEffect(()=>{
        let filas = [];
        // Verificar si existe el campo "Repuestos" y si tiene valores
        datofactura && datofactura.forEach((dato)=>{
            
            if (dato.Ordenes && Array.isArray(dato.Ordenes) && dato.Ordenes.length > 0 && dato.Taller !== 'Goldmund (central)') {
                dato.Ordenes.forEach((ordenes) => {
                    if (dato.Estado === 'Pendiente') {
                        const fila = {
                            Fecha: new Date(dato.Fecha.seconds * 1000).toLocaleString(),
                            Estado: dato.Estado,
                            iddoc: dato.iddoc,
                            Taller: dato.Taller,
                            Total: dato.Total,
                            Ordenes: [{
                                Orden: ordenes.Num_Os,
                                Articulo: ordenes.Articulo,
                                Descripcion: ordenes.Descripcion,
                                Categoria: ordenes.Categoria,
                                Cliente: ordenes.Cliente,
                                Desperfecto: ordenes.Desperfecto_declarado,
                                Costo: (ordenes.ManoDeObra !== 0 && ordenes.ManoDeObra != null) ? ordenes.ManoDeObra : costomasreciente(ManoDeObraData, ordenes.Tipo, ordenes.TimeStamp)
                            }]
                        }
                        filas.push(fila);
                    }
                });
            }
        })
        setFilas(filas)
        // setRemitosPendientes(filas.filter((etiqueta) => etiqueta.UltEstado === "Pendiente"));
    },[datofactura])
    
    const filasAgrupadas = filas && filas.reduce((acc, item) => {
        if (!acc[item.iddoc]) {
        acc[item.iddoc] = [];
        }
        acc[item.iddoc].push(item);
        return acc;
    }, {});
    
    {filasAgrupadas && Object.entries(filasAgrupadas).map(([lote, items], index) => {
        // console.log(items[0].Ordenes)
        {Array.isArray(items[0].Ordenes) && items[0].Ordenes.map((item, idx) => (
            console.log()
        ))}
    })}

    const handleSelectAll = () => {
        const newValue = !selectAll; // Cambiar el valor de selectAll
        setSelectAll(newValue); // Actualizar el estado
        if (newValue) {
            // Si se selecciona todo, actualizar las órdenes seleccionadas
            setOrdenesSeleccionadas(ordenesmesactual); // Seleccionar todas las órdenes
        } else {
            // Si se deselecciona todo, vaciar las órdenes seleccionadas
            setOrdenesSeleccionadas([]); // Deseleccionar todas las órdenes
        }
    };
    
    return(
        <div>
            <ModalFactura onSubmit={facturar} />
            <h3>Facturacion:</h3>
            <div className="accordion" id="accordion" style={{marginTop: '25px'}}>
                {/* Item 1 */}
                <div className="accordion-item">
                    <div className="row accordion-button collapsed m-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className="col">
                            <h4 className="accordion-header" id="headingOne">
                                Detalle sobre ordenes de servicio 
                            </h4    >
                        </div>
                        {ordenesSeleccionadas &&
                        <div className="col d-flex justify-content-end" style={{paddingRight:"2rem"}}>
                            <p style={{fontSize:"30px", marginBottom:"0"}}><strong>Total:</strong> ${montoOrden}</p>
                        </div>
                         }
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
                        <div className="accordion-body">
                            <div className='row' style={{marginTop: '25px'}}>
                                <h4 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Ordenes pendientes de facturacion:</h4>
                                <div className='container'>
                                     {/* Botón para seleccionar/des seleccionar todas las filas */}
                                    <div className="col d-flex justify-content-end">
                                        <button className="btn btn-primary mb-2" onClick={handleSelectAll}>
                                            {selectAll ? 'Deseleccionar Todo' : 'Seleccionar Todo'}
                                        </button>
                                    </div>
                                    {ordenesmesactual && !selectAll &&
                                        <Table showDelete={true} listaOrdenes={ordenesmesactual} source={"orden"} rows={ordenesmesactual.length} settingcolumns={OrdenesReparadas} globalfilter={true} statefilter={false} exporttable={false} showRequest={true} showSumar={true} setOrdenesSeleccionadas={setOrdenesSeleccionadas}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                {/* Item 2 */}
                {/* <div className="accordion-item"> */}
                    {/* <div className="row accordion-button collapsed m-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        <div className="col">
                            <h4 className="accordion-header" id="headingTwo">
                                Detalle sobre repuestos facturables
                            </h4>
                        </div>
                        {ordenesSeleccionadas &&
                        <div className="col d-flex justify-content-end" style={{paddingRight:"2rem"}}>
                            <p style={{fontSize:"30px", marginBottom:"0"}}><strong>Total:</strong> ${sumaPrecioTaller}</p>
                        </div>
                         }
                    </div> */}
                    {/* <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        Detalle sobre repuestos facturables
                        </button>
                    </h2> */}
                    {/* <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                        <div className="accordion-body">
                            <div className='row' style={{marginTop: '50px'}}>
                                <h4 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Repuestos pendientes de facturacion:</h4>
                                <div className='container'>
                                    {repuestosFacturar[0] && 
                                        <Table showDelete={true} listaOrdenes={repuestosFacturar} rows={repuestosFacturar.length} settingcolumns={columnsRepuestosFacturar} globalfilter={true} statefilter={false} exporttable={false} showRequest={true}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}
                <div className="accordion-item">
                    <div className="row accordion-button collapsed m-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                        <div className="col">
                            <h4 className="accordion-header" id="headingThree">
                                Ordenes ya facturadas
                            </h4>
                        </div>
                        {/* {ordenesSeleccionadas &&
                        <div className="col d-flex justify-content-end" style={{paddingRight:"2rem"}}>
                            <p style={{fontSize:"30px", marginBottom:"0"}}><strong>Total:</strong> ${sumaPrecioTaller}</p>
                        </div>
                         } */}
                    </div>
                    {/* <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        Detalle sobre repuestos facturables
                        </button>
                    </h2> */}
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                        <div className="accordion-body">
                            <div className='row' style={{marginTop: '50px'}}>
                                {/* <h4 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Ya facturados:</h4> */}
                                <div className='container'>
                                    {/* {ordenesmesactual && 
                                        <Table showDelete={true} listaOrdenes={ordenesmesactual} source={"orden"} rows={ordenesmesactual.length} settingcolumns={OrdenesReparadas} globalfilter={true} statefilter={false} exporttable={false} showRequest={true} setOrdenesSeleccionadas={setOrdenesSeleccionadas}/>
                                    } */}
                                    <div>
                                        
                                        {/* <div className="container" style={{marginTop: '25px'}}> */}
                                        <div style={{marginTop: '25px'}}>
                                        {filasAgrupadas && Object.entries(filasAgrupadas).map(([lote, items], index) => (
                                            
                                            <div className="accordion" key={index}>
                                                <div className="card">
                                                <div className="card-header row" id={`heading-${lote}`} 
                                                    role="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse-${lote}`}
                                                    aria-expanded="true"
                                                    aria-controls={`collapse-${lote}`}>
                                                        <div className="col">
                                                            <h2>
                                                                Registro: {items[0].iddoc}
                                                            </h2>
                                                            <label>
                                                                Fecha: {items[0].Fecha}
                                                            </label>
                                                            {/* <h5>
                                                                Total: ${items[0].Total}
                                                            </h5> */}
                                                        </div>
                                                        <div className="col d-flex justify-content-end">
                                                            <h3>
                                                                Estado: {items[0].Estado}
                                                            </h3>
                                                        </div>                                                    
                                                </div>

                                                <div
                                                    id={`collapse-${lote}`}
                                                    className="collapse"
                                                    aria-labelledby={`heading-${lote}`}
                                                    data-parent="#accordionExample"
                                                >
                                                    <div className="card-body">
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>Orden</th>
                                                            <th>Articulo</th>
                                                            <th>Descripción</th>
                                                            <th>Categoria</th>
                                                            <th>Cliente</th>
                                                            <th>Desperfecto</th>
                                                            <th>Costo</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {items.map((group, groupIdx) => (
                                                            Array.isArray(group.Ordenes) && group.Ordenes.map((item, itemIdx) => {
                                                                return(
                                                                    <tr key={`${groupIdx}-${itemIdx}`}>
                                                                    <td>{item.Orden}</td>  
                                                                    <td>{item.Articulo}</td>
                                                                    <td>{item.Descripcion}</td>
                                                                    <td>{item.Categoria}</td>
                                                                    <td>{item.Cliente}</td>
                                                                    <td>{item.Desperfecto}</td>
                                                                    <td>{item.Costo?item.Costo:0}</td>
                                                                </tr>
                                                                )
                                                            })
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        ))}
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <div className='row'>
                    <button className="btn btn-primary col" onClick={() => {resumir()}} style={{marginTop: '50px'}}>
                        Generar resumen
                    </button>
                </div>
                {/* {resumen && 
                    <div style={{marginTop: '50px'}}>
                        <h2>Resumen:</h2>
                        <ul>
                            {resumen.map((documento, index) => (
                                <li key={index}>
                                    <strong>ID Documento:</strong> {documento.iddoc}<br />
                                    <strong>Cliente:</strong> {documento.Cliente}<br />
                                    <strong>Fecha:</strong> {documento.Fecha}<br />
                                    <strong>Estado:</strong> {documento.Estado}<br />
                                    <strong>Artículo:</strong> {documento.Articulo}<br />
                                    <strong>Descripción:</strong> {documento.Descripcion}<br />
                                    <strong>Categoría:</strong> {documento.Categoria}<br />
                                    <strong>Falla:</strong> {documento.Desperfecto_declarado}<br />
                                    <strong>Costo:</strong> ${documento.ManoDeObra}<br />
                                </li>
                            ))}
                        </ul>
                        <p style={{marginTop: '30px'}}><strong>Total ordenes seleccionadas a facturar:</strong> ${montoOrden}</p>
                        <p style={{marginTop: '30px'}}><strong>Total repuestos seleccionados a facturar:</strong> $0</p>
                    </div>
                } */}
                {resumen &&
                <div>
                    <div className="card" style={{marginTop: '50px', padding: window.innerWidth <= 768 ? '10px' : '20px'}}>
                        <h2 style={{marginTop: '10px'}}>Resumen:</h2> 
                        <div className="card-body justify-content-center" style={{marginTop: '10px', display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px'}}>
                            {resumen.map((documento, index) => (
                                <div key={index} style={{border: '1px solid #ccc', borderRadius: '5px', padding: '10px', marginBottom: '10px'}}>
                                    <p><strong>ID Documento:</strong> {documento.iddoc}</p>
                                    <p><strong>Cliente:</strong> {documento.Cliente}</p>
                                    <p><strong>Fecha:</strong> {documento.Fecha}</p>
                                    <p><strong>Estado:</strong> {documento.Estado}</p>
                                    <p><strong>Artículo:</strong> {documento.Articulo}</p>
                                    <p><strong>Descripción:</strong> {documento.Descripcion}</p>
                                    <p><strong>Categoría:</strong> {documento.Categoria}</p>
                                    <p><strong>Tipo:</strong> {documento.Tipo}</p>
                                    <p><strong>Falla:</strong> {documento.Desperfecto_declarado}</p>
                                    <p><strong>Costo:</strong> ${documento.ManoDeObra}</p>
                                    {/* Agrega aquí más campos si es necesario */}
                                </div>
                            ))}  
                        </div>
                        <p style={{marginTop: '30px', fontSize:'2rem'}}><strong>Total ordenes seleccionadas a facturar:</strong> ${montoOrden}</p>
                        {/* <p style={{marginTop: '30px', fontSize:'2rem'}}><strong>Total repuestos a facturar:</strong> ${sumaPrecioTaller}</p> */}
                    </div>
                
                    <div className='row'>
                        <button className="btn btn-success col" data-bs-toggle="modal" data-bs-target="#modalFactura" style={{marginTop: '50px'}}>
                            Crear factura
                        </button>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}