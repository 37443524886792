import { createSlice } from '@reduxjs/toolkit'

export const FeedbackSlice = createSlice({

    name: 'Feedback',
    initialState: [],
    reducers: {
        addFeedback: (state, action) => {
            return action.payload
        },
    }
})
export const { addFeedback } = FeedbackSlice.actions
export default FeedbackSlice.reducer