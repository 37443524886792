import React, { useState, useEffect } from 'react'
import { Table } from './table'
import { columnsRepuestos, columnsRepuestosConfirmar } from './columns';
import { AddOrders, GetCategorias, GetRepuesto, GetSkuList, GetDolar, Getpvp} from './dbfirebase'
import nuevodocumento from '../images/nuevo-documento.png'
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc } from 'firebase/firestore';
import { db } from './firebase';
import { addNewOrder, clearNewOrder, addSparepart, updateSparepart} from '../store/ordersInfo/newOrderSlices';
import { useGeneral } from '../context/generalContext';
import { addRepuestosInfo } from '../store/repuestosInfo/repuestosInfoSlices';
import deleterow from '../images/delete.png'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {Ayuda} from './Help'

export const ListaPrecios = () => {

    const [dato, setDato] = useState()
    const [categoria, setCategoria] = useState()
    const [tipo, setTipo] = useState()
    const [repuestos, setRepuestos] = useState('')
    const [articuloSeleccionado, setArticuloSeleccionado] = useState('')
    const [sectionState, setSectionState] = useState('')
    const [cdolar, setCdolar] = useState(1)
    const userData = useSelector(state => state.userInfo)
    const newOrder = useSelector(state => state.newOrder)
    const [pvp, setPvp] = useState(1)
    const [producto, setProducto] = useState()
    const [searchInputs, setSearchInputs] = useState(
        {
            Categoria: '',
            Tipo: '',
            Articulo: '',
        }
    )
    const navigate = useNavigate();

    //TODO: armado de pedido en rdx
    const [completeOrder, setCompleteOrder] = useState(
        {
            Email: userData.Email,
            UUID: userData.UUID,
            Permiso: userData.Permiso,
            Taller: userData.Taller,
            Garantia: false,
            Direccion: userData.Direccion,
            Titular: userData.Titular,
            Cuit: userData.Cuit,
            Telefono: userData.Telefono,
            //Fecha: new Date().toLocaleString(),
            Fecha: new Date().toLocaleString().split(',')[0],
            TimeStamp: new Date().toLocaleString(),
            TimeStamp: new Date().toLocaleString(),
            Cliente: userData.Taller,
            Articulo: ""
           
        }
    )

    const [categorias, setCategorias] = useState([])
    const [listaPedido, setListaPedido] = useState([])
    const [pedidofiltrado, setPedidofiltrado] = useState([])
    const [pedido, setPedido] = useState([])
    const [sku, setSku] = useState()
    
    //const [saveOrder, setSaveOrder] = useState(false)

    const { generateSecretKey } = useGeneral()

    const dispatch = useDispatch()

    // const data = async(categ) =>{ 
    //     const Categoria = await GetCategorias(categ)
    //     setCategoria(Categoria[0].Categoria)
    // }

    const ClearForm = () => {
        document.getElementById("Form").reset();
    }

    // useEffect(()=>{
    //     data('Categorias')
    // },[])

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        //TODO: cambiar setteos 
        setCompleteOrder((prevState) => ({ ...prevState, [name]: value }));

        setSearchInputs({ ...searchInputs, [name]: value })

    };

    const dolar = async() =>{
        const d = await GetDolar()
        setCdolar(d)
    }

    const Pvp = async() =>{
        const d = await Getpvp()
        setPvp(d)
    }

    // const handleFormSubmit = async() => {
    //     const upload = await NewTaller(taller)
    //     ClearForm()
    // }

    // const type = async(categ) =>{
    //     const Tipo = await GetCategorias(categ)
    //     setTipo(Tipo[0][`${taller.Tipo}`])
    // }

    //datos categoria
    const Categoria = async () => {
        const cat = await GetCategorias()
        // console.log(cat)
        setCategorias(cat)
        const categ = cat.filter(objeto => Object.keys(objeto)[0] !== "Categoria")
        // setCategoria(categ)
        // console.log(categ)
    }

    const Repuestos = async (categoria, articulo) => {
        const Articulos = await GetRepuesto(categoria, articulo)
        setRepuestos(Articulos)

        // console.log(articulo)
        setArticuloSeleccionado(articulo)
    }


    const GetSku = async() =>{
        const Skus = await GetSkuList()
        setSku(Skus.sku)
    }

    const obj = {}
    categorias.map((item) =>{
        [item].map((i)=>{
            Object.assign(obj, i);
        })
    }) 
    
    const buscarCategoria = (objeto, claveBuscada, ruta = []) => {
        for (let nombreCategoria in objeto) {
            let categoria = objeto[nombreCategoria];
            if (typeof categoria === 'object') {
                if (claveBuscada in categoria) {
                    ruta.push(nombreCategoria);
                    ruta.push(claveBuscada);
                    return ruta;
                } else {
                    let resultado = buscarCategoria(categoria, claveBuscada, ruta.concat(nombreCategoria));
                    if (resultado !== false) {
                        let grupo = buscarClave(categorias,resultado)
                        return [resultado, grupo]
                    }
                }
            }
        }
        return false;
    }

    function buscarClave(obj, valorBuscado) {
        for (let i=0; i<=obj.length; i++){
            for (let clave in obj[i]) {
                if (clave === valorBuscado[0]) {
                    return obj[i].Grupo;
                }
            }
        }
        return null; // valor no encontrado
    }

    useEffect(() => {
        Categoria()
        GetSku()
        Pvp()
    }, [])

    useEffect(() => {
        const cate = buscarCategoria(obj, completeOrder.Articulo)[1]
        if (cate){
            Repuestos(cate, completeOrder.Articulo)
        }
        // if (completeOrder.Categoria && completeOrder.Articulo) {
        //     Repuestos(completeOrder.Categoria, completeOrder.Articulo);
        //   }
        
    }, [completeOrder.Articulo])

    // const listaprecio = []
    // const lista = []
    // const listaprecio = []
    // const lista = []

    // if (repuestos) {
    //     const nuevosProductos = repuestos && Object.entries(repuestos).map((repuestos) => {
    //         repuestos[1].map((r) => {
    //             const PrecioPublico = Object.values(r)[0].PrecioPublico
    //             const PrecioTaller = Object.values(r)[0].PrecioTaller
    //             const Descripcion = Object.values(r)[0].Descripción
    //             const c = categorias.map((categoria) => (Object.keys(categoria).includes('Categoria') ? categoria.Categoria[`${completeOrder.Categoria}`] : null)).filter((valor) => valor !== null)
    //             lista.push({ Categoria: completeOrder.Categoria || '', Tipo: completeOrder.Tipo || '', Articulo: completeOrder.Articulo || '', Codigo: Object.keys(r) || '', Descripcion, mdo: c[0] ? c[0][`${completeOrder.Tipo} `] : '' })
    //         });
    //         return lista
    //     });
    //     listaprecio.push(lista)
    //     console.log(listaprecio)
    // };
    // if (repuestos) {
    //     const nuevosProductos = repuestos && Object.entries(repuestos).map((repuestos) => {
    //         repuestos[1].map((r) => {
    //             const PrecioPublico = Object.values(r)[0].PrecioPublico
    //             const PrecioTaller = Object.values(r)[0].PrecioTaller
    //             const Descripcion = Object.values(r)[0].Descripción
    //             const c = categorias.map((categoria) => (Object.keys(categoria).includes('Categoria') ? categoria.Categoria[`${completeOrder.Categoria}`] : null)).filter((valor) => valor !== null)
    //             lista.push({ Categoria: completeOrder.Categoria || '', Tipo: completeOrder.Tipo || '', Articulo: completeOrder.Articulo || '', Codigo: Object.keys(r) || '', Descripcion, mdo: c[0] ? c[0][`${completeOrder.Tipo} `] : '' })
    //         });
    //         return lista
    //     });
    //     listaprecio.push(lista)
    //     console.log(listaprecio)
    // };

    //------------------------------- pedidos -----------------------------------//
    
    const nuevoArreglo = [];
    if (repuestos) {
        const objetoCombinado = [repuestos.repuestos.reduce((resultado, objeto) => {

            return { ...resultado, ...objeto };
        }, {})];
        let index = 1; // Inicializamos el índice en 1
        for (let clave in objetoCombinado[0]) {
            nuevoArreglo.push({
                id: index++, // Añadimos el campo id con el valor del índice y luego incrementamos el índice
                Fecha: new Date().toLocaleString().split(',')[0],
                Articulo: clave,
                Descripcion: objetoCombinado[0][clave].Descripción,
                PrecioUsd: objetoCombinado[0][clave].PrecioU$D,
                PrecioPublico: objetoCombinado[0][clave].PrecioPublico * parseInt(producto?producto.Price:1),
                PrecioTaller: objetoCombinado[0][clave].PrecioTaller * parseInt(producto?producto.Price:1),
                UltEstado: "Pendiente",
            });
        }
    }
    
    useEffect(() => {
        setListaPedido([...listaPedido, pedido])
    }, [newOrder])

    // const getTiposPorCat = (categoria) => {
    //     categorias.filter((categoria) => (
    //         categoria.Grupo === completeOrder.Categoria)).map((producto) => {
    //             const nuevoProducto = {};
    //             for (const key in producto) {
    //                 if (key !== "Grupo") {
    //                     nuevoProducto[key] = producto[key];
    //                 }
                
    // }

    // useEffect(() => {
    //     console.log(searchInputs)
    //     console.log('cate', categorias)
    // }, [searchInputs])

    const filtrado = () => {
        
        const filteredPedido = listaPedido.flat().filter(item => item !== undefined); 
        
        // Crear un objeto para almacenar los últimos registros de cada artículo
        const uniqueItems = {};
        // Iterar sobre los registros y guardar el último para cada artículo
        filteredPedido.forEach(item => {
            
            if (item.cantidad !== "0") {
                item.Ult_Act = new Date().getTime()
                item.Sku = document.getElementById("Articulo").value
                const key = item.Articulo + '_' + item.Sku;
                
                if (!uniqueItems[key] || uniqueItems[key].Ult_Act < item.Ult_Act) {
                    item.Fecha = new Date().toLocaleString().split(',')[0]

                    // TODO: cambiar cuando pongan los precion en orden
                    // if (item.PrecioPublico === undefined || item.PrecioTaller === undefined) {
                    //     item.PrecioPublico = '0'
                    //     item.PrecioTaller = '0'
                    // }

                    uniqueItems[key] = item;

                }
            } else {
                const key = item.Articulo + '_' + item.Sku;
                delete uniqueItems[key];
                //delete uniqueItems[item.Articulo]; // Eliminar el artículo si la cantidad es 0
            }
        });

        // Convertir el objeto único en un array
        const uniquePedido = Object.values(uniqueItems);
        
        
        setPedidofiltrado(uniquePedido);
        // return uniquePedido;
    };
    
  
    
    //recibe el nombre del documento y genera el iddoc
    // const generateSecretKey = (docName) => {
    //     const docRef = doc(collection(db, docName))

    //     const id = docRef.id
    //     // Creamos un numero a partir del ID
    //     let number = []
    //     const charArray = id.substring(5, 10).split("");
    //     charArray.map((char) => {
    //         let numb = char.charCodeAt()
    //         number.push(numb)
    //     })
    //     // Usamos el numero generado como clave secreta
    //     const secretKey = number.join('').toString()

    //     return secretKey
    // }

    const onSubmitOrder = async () => {
        try {
            const docRef = doc(collection(db, 'Ordenes'))
            //let iddoc = generateSecretKey('Ordenes')
            //let order = { ...completeOrder, iddoc: generateSecretKey('Ordenes'), Repuestos: pedidofiltrado }

            let order = { ...newOrder, iddoc: generateSecretKey('Ordenes'), SparePart: true}
           
            order.TimeStamp = new Date()
            
            const newArray = order.Repuestos.map((item, index) => {
                return { ...item, id: index + 1 };
            });
            order.Repuestos = newArray
            console.log(order)
            // await AddOrders(order, docRef)
            
            
            document.getElementById("Form").reset();
            setRepuestos('')
            dispatch(clearNewOrder())

            setSectionState('cargado')
            toast.success('Datos cargados con exito', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true
            })
            // setTimeout(() => {
                // navigate('/ordenes');
            //   }, 2000);
        } catch (error) {
            console.log(error)
        }
    }


    //armado inicial del pedido (automatico)
    useEffect(() => {
        dispatch(addNewOrder({
            Email: userData.Email,
            Email_cli: userData.Email,
            UUID: userData.UUID,
            Permiso: userData.Permiso,
            Taller: userData.Taller,
            Garantia: false,
            Direccion: userData.Direccion,
            Titular: userData.Titular,
            Cuit: userData.Cuit,
            Telefono: userData.Telefono,
            Fecha: new Date().toLocaleString().split(',')[0],
            TimeStamp: new Date().toLocaleString(),
            Cliente: userData.Taller,
        }))
        return () => {
            dispatch(clearNewOrder())
        }
    }, [userData])

    const handleEliminarRepuesto = (repuesto) => {
        
        dispatch(addSparepart({ ...repuesto, cantidad: 0 }));
        
    };
    
    // useEffect(() => {
    // if (pvp && pvp.Precios) { 
    //     const productoFiltrado = pvp.Precios.find((producto) => producto.ItemCode === searchInputs.Articulo);
    //     setProducto(productoFiltrado);
    // }
    // }, [searchInputs.Articulo, pvp]);

    useEffect(() => {
        if (pvp && pvp.Precios) { 
            const productoFiltrado = pvp.Precios.find((producto) => producto.ItemCode === searchInputs.Articulo);
            setProducto(productoFiltrado);
        }
    }, [pvp]);

//---------------------- aumenta y reduce cantidad --------------------------//

const [cantRep, setCantRep] = useState(1)

    const handleQuantityChange = (operation, index) => {
        // Clona el array de repuestos para no modificar el original directamente
        const repuestosCopy = [...newOrder.Repuestos];
        
        // Verifica si el índice proporcionado es válido
        if (index >= 0 && index < repuestosCopy.length) {
            // Clona el objeto en el índice dado para no modificarlo directamente
            const repuestoCopy = { ...repuestosCopy[index] };
    
        
            let cantidad = repuestoCopy.cantidad;
    
        
            if (operation === "aumentar") {
                cantidad ++;
            } else if (operation === "disminuir" && cantidad > 1) {
                cantidad --;
            }
    
            // Actualiza la cantidad en el objeto clonado
            repuestoCopy.cantidad = cantidad;
    
            // Actualiza el objeto en el array clonado
            dispatch(updateSparepart({ index, updatedSparepart: repuestoCopy }));
        }
    };

    
    
    
      //  if (operation === '+') {
      //      repuestosCopy[index].cantidad += 1;
      //  } else if (operation === '-' && repuestosCopy[index].cantidad > 0) {
       //     repuestosCopy[index].cantidad -= 1;
      //  }
 // Aquí podrías actualizar el estado con el nuevo array modificado
        // setNewOrder({ ...newOrderCopy, Repuestos: repuestosCopy });
    // console.log([...newOrder.Repuestos])
   
 
    return (
        <div>
            {/* <div className='row'>
                <h1 className='col-12 d-flex justify-content-start' style={{ margin: '0 auto' }}>Lista de precios</h1>
               <button className='col-4 d-flex justify-content-end'style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}} onClick={Nuevaorden}>
                    <div className='container d-flex justify-content-end'>
                    <img src={nuevodocumento} width="60rem" height="60rem" alt=''/>
                    <p className="text-center">Agregar producto</p>
                    </div>
                </button>
                
            </div> */}
            <div className='row'>
                <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Lista de precios</h3>
                <Ayuda section="pedir"/>
            </div>
            <ToastContainer />
            <h4 style={{ marginTop: '50px' }}>Buscar producto</h4>
            <div className='d-flex justify-content-start' style={{ marginTop: '50px' }}>
                <form id='Form'>
                    <div className="row">
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{ alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Categoria:</label>
                            <input
                                type="text"
                                name="Categoria"
                                className="form-control"
                                placeholder="Categoria"
                                id="Categoria"
                                list='categoria'
                                autoComplete="off"
                                autoFocus
                                onChange={handleFormChange}
                                style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                disabled={true}
                                //value = {buscarCategoria(obj, searchInputs.Articulo)[1]}
                            />
                            <datalist id="categoria">
                                {categorias && categorias.map((categoria) => (
                                    Object.keys(categoria).includes('Categoria') ? (
                                        Object.keys(categoria.Categoria).map((c) => (
                                            <option key={c} value={c} />
                                        ))
                                    ) : null
                                ))}
                            </datalist>
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{ alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Tipo:</label>
                            <input
                                type="text"
                                name="Tipo"
                                className="form-control"
                                placeholder="Tipo"
                                id="Tipo"
                                list='tipo'
                                autoComplete="off"
                                onChange={handleFormChange}
                                style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                disabled={!completeOrder.Categoria || completeOrder.Articulo}
                                //value = {searchInputs.Articulo && buscarCategoria(obj, searchInputs.Articulo)[0][0]}
                                //value = {searchInputs.Articulo && buscarCategoria(obj, searchInputs.Articulo)[0][0]}
                            />
                            <datalist id="tipo">
                                {completeOrder.Categoria && categorias.filter((categoria) => (
                                    categoria.Grupo === completeOrder.Categoria)).map((producto) => {
                                        const nuevoProducto = {};
                                        for (const key in producto) {
                                            if (key !== "Grupo") {
                                                nuevoProducto[key] = producto[key];
                                            }
                                        }
                                        return Object.keys(nuevoProducto).map((art) =>
                                            <option key={art} value={art} />,
                                        )
                                    })
                                }
                            </datalist>
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{ alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Articulo:</label>
                            <input
                                type="text"
                                name="Articulo"
                                className="form-control"
                                placeholder="Articulo"
                                id="Articulo"
                                autoComplete="off"
                                list='articulo'
                                onChange={(e) => { e.target.value = e.target.value.toUpperCase(); handleFormChange(e); }}
                                style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                //disabled={!completeOrder.Categoria || !completeOrder.Tipo}
                                //disabled={!completeOrder.Categoria || !completeOrder.Tipo}
                            />
                            <datalist id="articulo">
                                {completeOrder.Tipo && categorias.filter((categoria) => (
                                    categoria.Grupo === completeOrder.Categoria)).map((producto) => {
                                        try{
                                            return Object.keys(producto[`${completeOrder.Tipo}`][0]).map((codigo) => (
                                                <option key={codigo} value={codigo} />
                                            ))
                                        }catch (error){
                                            return null
                                            }
                                }) || sku &&
                                    sku.map(art => (
                                            <option key={art} value={art}>
                                            {art}
                                            </option>
                                        ))
                                    }
                            </datalist>
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{ alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Descripcion:</label>
                            <input
                                type="text"
                                name="Descripcion"
                                className="form-control"
                                placeholder="Descripcion"
                                id="descripcion"
                                //onChange={handleFormChange
                                //onChange={handleFormChange
                                disabled={true}
                                style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                
                                value={completeOrder.Articulo && categorias.filter((categoria) => (
                                        categoria.Grupo === completeOrder.Categoria)).map((producto) => {
                                    try{
                                        return producto[`${completeOrder.Tipo}`][0][`${completeOrder.Articulo}`]
                                    }catch(error){
                                        return null
                                    }
                                    })}
                            />
                        </div>
                        {/* <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>:</label>
                                <input 
                                    type = "text"
                                    name = "Cuit"
                                    className = "form-control"
                                    placeholder = "Cuit"
                                    id = "Cuit"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Provincia:</label>
                                <input 
                                    type = "text"
                                    name = "Provincia"
                                    className = "form-control"
                                    placeholder = "Provincia"
                                    id = "Provincia"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                                <input 
                                    type = "text"
                                    name = "Telefono"
                                    className = "form-control"
                                    placeholder = "Telefono"
                                    id = "Telefono"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Mail:</label>
                                <input 
                                    type = "text"
                                    name = "Email"
                                    className = "form-control"
                                    placeholder = "Mail"
                                    id = "Email"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                        </div> */}
                    </div>
                </form>
            </div>
            {/* <div className='d-flex justify-content-end' style={{ marginBottom: '20px' }}>
               <button type="submit" onClick={handleFormSubmit} className='btn btn-outline-success'>Guardar</button>     
            </div>*/}
            <div className='row d-flex justify-content-center' style={{ marginTop: '50px' }}>
                {
                    sectionState === 'loading'
                    &&
                    <div className="d-flex align-items-center d-flex justify-content-center" style={{ marginBottom: '150px' }}>
                        <strong>Cargando...</strong>
                        <div className="spinner-border ml-auto" style={{ marginBottom: '20px' }} role="status" aria-hidden="true"></div>
                    </div>
                }
                {
                    nuevoArreglo && completeOrder.Articulo
                    &&
                    <Table
                        listaOrdenes={nuevoArreglo} source={"orden"} settingcolumns={columnsRepuestos}
                        rows={20} showQuantity3={true} pedido={pedido} setPedido={setPedido}
                        articuloSeleccionado={articuloSeleccionado}
                    />
                }


                {
                    sectionState === 'cargado'
                    &&
                    <h3>Pedido cargado correctamente</h3>
                }
            </div>

            {/* salteadeo */}
            {/*<button
                className="btn btn-secondary col-12"
                onClick={filtrado}
            // disabled={pedidofiltrado.length < 1}
            >
                Cargar pedido
            </button> */}

            <div className='' style={{ marginTop: '30px' }}>
                <h5>
                    Pre-visualizacion de los repuestos a solicitar
                </h5>


                {<table className="table table-striped table-hover" style={{textAlign: "center"}}>
                    <thead className="thead-light">
                        <tr>
                        <th>Fecha</th>
                        <th>Repuesto</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        {/* <th>Eliminar</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {newOrder.Repuestos.map((fila, index) => (
                        <tr key={index} >
                            <td>{fila.Fecha}</td>
                            <td>{fila.Articulo}</td>
                            <td>{fila.Descripcion}</td>

                            <td>
                                <div>
                                    <button className="btn btn-light" style={{marginLeft: '15px', marginRight:'15px' }}  onClick={() => handleQuantityChange('aumentar', index)} > + </button>
                                    {fila.cantidad}
                                    <button className="btn btn-light"style={{ marginLeft: '15px', marginRight:'15px'}}  onClick={() => handleQuantityChange('disminuir', index)}> - </button>
                                </div>    
                            </td>
                            {/* <td>
                            <button onClick={() => handleEliminarRepuesto(fila)} style={{border: 'none' }} >
                               <img src={deleterow} alt="Eliminar" style={{ width: '35px', height: '35px', border: 'none' }}/>
                            </button>
                            </td> */}
                        </tr>
                        ))}
                    </tbody>
                </table>}
                   

            </div>

            <button
                className="btn btn-success col-12"
                onClick={onSubmitOrder}
                //disabled={pedidofiltrado.length < 1}
                disabled={!newOrder.Repuestos.length > 0}
            >
                Confirmar
            </button>

        </div>
    )
}