import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom' //Link nos permite crear un acceso a otras componentes
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'
import { Showafterloged } from './hidden'
import { useDispatch, useSelector } from 'react-redux';
import { GetOrders, GetCostoManoDeObra } from './dbfirebase'
import { addOrdersInfo } from '../store/ordersInfo/ordersInfoSlices';
import { addRepuestosInfo } from '../store/repuestosInfo/repuestosInfoSlices';
import { addFeedback } from '../store/ordersInfo/FeedbackSlices';
import notificacion from '../images/notification.gif'
import {useAuth} from "../context/authcontext";
import { useGeneral } from '../context/generalContext'
import { addUserInfo } from '../store/userInfo/userInfoSlices';
import { addManoDeObra } from '../store/ordersInfo/ManoObraSlices';

export const Navbar = () => {

  const {user, logout} = useAuth();
  
  const [update, setUpdate] = useState(false)
  const [dataOrders, setDataOrders] = useState()
  const [randomValue, setRandomValue] = useState(Math.random());
  const { userData } = useGeneral()
  const userdatos = useSelector(state => state.userInfo)
  const ordersData = useSelector(state => state.orderInfo)
  const dispatch = useDispatch()
  const perfil = async () => {
    const dato = await userData
    //skick(dato)
    dispatch(addUserInfo(dato))
  }

  const mdo = async() =>{
    try{
      const dato = await GetCostoManoDeObra()
      const data = dato.map(item => 
        ({...item,
        Fecha:item.Fecha.seconds}))
      dispatch(addManoDeObra(data))
    }catch{}
  }


  const kick = async(dato) => {
      if (dato.App === "God" || dato.App === 'Service'){
          //await logout()
      }else{
          await logout()
      }
  }

  useEffect(() => {
      perfil()
  }, [user])
  
  const showNotification = () => {
    if ("Notification" in window && Notification.permission === "granted") {
        const notificationOptions = {
        body: "¡Hola! Se han actualizado las ordenes de servicio.",
        icon: notificacion, // Opcional: Ruta a un icono para la notificación
        };
    
        new Notification("Ordenes de servicio actualizadas", notificationOptions);
    }
  };

  const data = async () => {
    try {
        await GetOrders(userdatos, (dats) => {
        const feedbackConCalificacion = dats && dats.filter(item => item.hasOwnProperty('Calificacion'));
        const sumaCalificaciones = feedbackConCalificacion && feedbackConCalificacion.reduce((suma, item) => Number(suma) + Number(item.Calificacion), 0);
        const promedioCalificaciones = feedbackConCalificacion && feedbackConCalificacion.length ? sumaCalificaciones / feedbackConCalificacion.length : 0;
        const updatedDats = dats.map((t) => {
          return { ...t, TimeStamp: t.TimeStamp.seconds };
        });
        //showNotification()
        
        setDataOrders(updatedDats)
        dispatch(addOrdersInfo(updatedDats));
        dispatch(addFeedback(promedioCalificaciones));
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
      if (user) {
          data()
          setUpdate(false)
          mdo()
      }
  }, [userdatos, update, randomValue])

  // arma una lista de repuestos con el numero de orden
  const getRepuestosPedidos = () => {
    let repuestosList = []
    dataOrders.map(ord => {
        if (ord.Repuestos) {
            if (ord.Repuestos[0] !== "") {
                ord.Repuestos.map(({ Estado, ...rep }) => {
                    repuestosList = [...repuestosList, { ...rep, iddoc: ord.iddoc, Cliente: ord.Cliente, Fecha: ord.Fecha, Falla: ord.Desperfecto_declarado, Garantia: ord.Garantia}]
                })
        
            } else {
                console.log(ord)
            }
        }
    });
    return repuestosList
  }
  
  useEffect(() => {
    dataOrders
        &&
        dispatch(addRepuestosInfo(getRepuestosPedidos()))
  }, [dataOrders])

return(
  <nav
    className="navbar navbar-expand-lg navbar-light navigation"
    style={{width: '90%',
      backgroundColor: '#ffffff', textAlign: 'center',
      margin: '0 5%'
    }}>

    {/*Link que lleva a la raiz con el nombre de la pagina*/}
    <Link className="navbar-brand mx-4" to="/">
      <img src={ServicesPostventaLogo} width="210" height="60"></img>
      </Link>

    {/*Agregamos un boton de navegacion*/}
    <button className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>

    <div className='col'>
      <Showafterloged/>
    </div>
  </nav>
  )
}
