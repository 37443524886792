import { createSlice } from '@reduxjs/toolkit'

export const repuestosInfoSlice = createSlice({

    name: 'repuestosInfo',
    initialState: [],
    reducers: {
        addRepuestosInfo: (state, action) => {
            return action.payload
        },
    }
})
export const { addRepuestosInfo } = repuestosInfoSlice.actions
export default repuestosInfoSlice.reducer