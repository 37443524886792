import React from 'react';

export const GlobalFilter = ({filter, setFilter}) => {
    
    return (
        <span>
            Buscar: {' '}
            <input value = {filter || ''} onChange = {(e) => {setFilter(e.target.value)}} style={{width: '15rem'}}/>
        </span>
    )
}

export const StateFilter = ({filter, setFilter}) => {
    
    return (
        <div className="btn-group dropright" >
            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Filtrar por estado:
            </button> 
            <div className="dropdown-menu dropdown-menu-right">
                <button className='dropdown-item' onClick = {() => {setFilter('Pendiente')}} style={{width: '10rem'}} >Pendientes</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Reparando')}} style={{width: '10rem'}}>Reparando</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Envio a central')}} style={{width: '10rem'}}>Envio a central</button>
                <button className='dropdown-item' onClick = {() => {setFilter('En central')}} style={{width: '10rem'}}>En central</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Diagnosticado')}} style={{width: '10rem'}}>Presupuesto pend.</button>
                <button className='dropdown-item' onClick = {() => {setFilter('PresAceptado')}} style={{width: '10rem'}}>Presupuesto acept.</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Reparado')}} style={{width: '10rem'}}>Reparado</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Finalizado')}} style={{width: '10rem'}}>Finalizado</button>
               
                <button className='dropdown-item' onClick = {() => {setFilter('')}} style={{width: '10rem'}}>Quitar filtro</button>
            </div>
        </div>
    )
}

export const StateFilterRepuestos = ({filter, setFilter}) => {
    
    return (
        <div className="btn-group dropright" >
            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Filtrar por estado:
            </button> 
            <div className="dropdown-menu dropdown-menu-right">
                <button className='dropdown-item' onClick = {() => {setFilter('Pendiente')}} style={{width: '10rem'}} >Pendientes</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Preparando')}} style={{width: '10rem'}}>Preparando</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Despacho')}} style={{width: '10rem'}}>En despacho</button>
                <button className='dropdown-item' onClick = {() => {setFilter('SinStock')}} style={{width: '10rem'}}>Sin stock</button>
                <button className='dropdown-item' onClick = {() => {setFilter('Transporte')}} style={{width: '10rem'}}>En transporte</button>
                <button className='dropdown-item' onClick = {() => {setFilter('')}} style={{width: '10rem'}}>Quitar filtro</button>
            </div>
        </div>
    )
}

export const DateFilter = ({filter, setFilter}) => {
    const currentDate = new Date().toISOString().split('T')[0];

    const handleDateChange = (event) => {
        const date = event.target.value;
        setFilter(date);
      };

    return (
        <div className="btn-group dropright">
        <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Filtrar por fecha:
        </button>
        <div className="dropdown-menu">
            <input
                type="date"
                className="dropdown-item"
                onChange={handleDateChange}
                value={filter}
                style={{ width: '10rem' }}
                max={currentDate}
            />
        </div>
    </div>
    )
}