import React,  { useState }  from 'react'
import google from '../images/google.png'
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/authcontext";
import { auth } from "../components/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import eyeIco from '../images/eyeduotone.png';
import { ToastContainer, toast } from 'react-toastify';
import { SlackErrorHandle } from '../components/SlackErrorMsj'

export const Login = () =>{

    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [mayuscula, setMayuscula] = useState(false)
    const [user, setUser] = useState({
        email : "",
        password : "",
    });

    const {signin} = useAuth();

    const [error, setError] = useState("");

    // estado para la visibilidad del password 
    const [passwordType, setPasswordType] = useState("password");

    //Funcion que maneja el checkbox
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    }

    //Funcion que captura los datos de los inputs y los coloca en el hook
    const handlechange = ({target: {name, value}}) => 
        setUser({...user, [name]: value});

    // "e" es el evento que vamos a capturar de la escritura en el formulario
    const handlesubmit = async (e) => {
        e.preventDefault();
        setError("");
        if(isChecked){
            try {
                await signin(user.email, user.password);
                navigate("/micuenta");
            }
            catch (error) {
                setError(true)
                SlackErrorHandle(`${new Date()}: -> Error en login -> ${error} -> Username: ${user.email}`)
                toast.error('Credenciales no validas',{
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true
                    })          
            }
        }else{
            toast.error('Debe acceptar los terminos y condiciones',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })  
        }
    };

    //funcion para cambiar visibilidad de la contraseña
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }

    //Funcion que envia mail para resetear la contraseña y muestra un popup con un mensaje
    const triggerResetEmail = async () => {
        let messageOk = "Email enviado, revise su casilla (spam)"
        let messageError = "Dirección de correo no valida"
        try {
            await sendPasswordResetEmail(auth, user.email);
            toast.success(messageOk,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            }) 
        } catch (error) {
            setError(true);
            toast.error(messageError,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })   
        }
    };


    const termycond = () =>{
        navigate('/terminosycondiciones')
    }

    const isCapsLockOn = (event) => {
        const caps = event.getModifierState && event.getModifierState('CapsLock');
        setMayuscula(caps)
        //return caps;
    }

    document.addEventListener('mouseenter',isCapsLockOn);
    document.addEventListener('keydown',isCapsLockOn);

    return(
        <div className="row">
            <ToastContainer />
            <div className='container d-flex justify-content-center'>
            <div className="col-md-4 p-3 mb-5 bg-white rounded">
            <div className = 'row d-flex justify-content-center'>
                <form onSubmit={handlesubmit} style={{width: '20rem'}}>
                    <h5 className='d-flex justify-content-center text-center'>
                        <strong>INICIA SESIÓN</strong>
                    </h5>
                    
                    <p className='d-flex justify-content-center text-center' style={{marginTop: '70px'}}>Inicia sesion con un correo electronico:</p>
                        <input 
                            type = "email"
                            name = "email"
                            onChange = {handlechange} 
                            className = "form-control"
                            placeholder = "Email"
                            id = "email"
                            autoFocus
                            />
                    <div className="passwordContainer mb-4 d-flex position-relative">
                        <input 
                            type = {passwordType}
                            name = "password"
                            onChange = {handlechange} 
                            className = "form-control"
                            placeholder = "Contraseña"
                            id = "password"
                            style={{marginTop: '15px'}}
                            />
                            <img className="eyeIcon mt-3"
                                    onClick={togglePassword}
                                    src={eyeIco}
                                    alt="o"/>
                    </div>
                    <p style={{fontSize:'15px'}}>{mayuscula?'Act. Mayusculas':''}</p>
                        <button className="btn btn-primary"
                                style={{marginTop: '15px', width: "100%"}}>
                            Iniciar Sesión
                        </button>
                        <div className ='form-check' style={{marginTop: '15px'}}>
                        <input style={{border: "2px solid blue"}} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked} onChange={handleCheckboxChange}/>
                            <p>
                                He leído y acepto las
                                <a href="#" onClick={() => termycond()}> Politicas de Privacidad </a>
                                y los
                                <a href="#" onClick={() => termycond()}> Terminos y condiciones</a>
                            </p>
                        </div>
                        <a className="passRecovery"
                            href="#" 
                            onClick={triggerResetEmail}
                            style={{marginTop: '15px'}}>
                            ¿Olvidaste tu contraseña?
                        </a>
                </form>
            </div>  
            </div>
            </div>
        </div>
    )
}