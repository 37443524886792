import React, {useEffect, useState, useRef} from 'react'
import {Actualizar, GetOrder, ActualizarManoDeObra} from './dbfirebase'
import { useMatch, useNavigate } from 'react-router-dom';
import { useAuth } from "../context/authcontext";
import { useSelector } from 'react-redux';

export const ModalFinalizado = () => {

    const match = useMatch('/ordenes/finalizado/:id')
    const id = match.params.id
    const navigate = useNavigate();
    const {user} = useAuth();
    const [motivo, setMotivo] = useState()
    const [selectedOperations, setSelectedOperations] = useState({});
    const [totalValue, setTotalValue] = useState(0)
    const orders = useSelector(state => state.ordersInfo)
    const ManoDeObraData = useSelector(state => state.manodeobra)
    const [mdo, setMdo] = useState()
    const [finalorder, setFinalOrder] = useState()
    const [kilometros, setKilometros] = useState('');

    const order = orders.filter((f) => f.Id === id)
    
    const textarea = useRef(null);
    
    const dataId = async () =>{
        const textareaValue = textarea.current.value;
        const newstate = await Actualizar('Reparado', {Fecha_finalizado: new Date().toLocaleString(), usuario: user.email}, textareaValue, id);
        if (finalorder){
            await ActualizarManoDeObra(finalorder[0].ManoDeObra, selectedOperations, kilometros, id)
        }
        navigate('/ordenes')
        // Limpia el contenido del textarea
        // console.log(finalorder[0].ManoDeObra)
        textarea.current.value = "";
    }

    const costomasreciente = (data, subcategoria, fecha) => {
        // const fechaActual = Math.floor(new Date().getTime() / 1000);
        const fechaActual = fecha
        let fechaMasCercana = Number.MAX_SAFE_INTEGER;
        let valoresCampoMasCercano = null;
        let diferencias = {};
        try {
            data.forEach((obj) => {
                // Suponiendo que `obj` es el objeto con las fechas como claves
                // let diferencias = {};
                Object.keys(obj).forEach((clave) => {
                    
                    if (clave !== 'Fecha') {
                        const [dia, mes, anio] = clave.split(/[/\-]/) //clave.split('/'); // Modificar para usar - tambien
                        const fechaEnSegundos = new Date(anio, mes - 1, dia).getTime() / 1000;
                        if(fechaEnSegundos < fechaActual){
                            
                            const diferenciaAbsoluta = Math.abs(fechaActual - fechaEnSegundos);
                            diferencias[clave] = diferenciaAbsoluta;
                            // Encontrar la clave con la menor diferencia absoluta
                            let claveMasCercana = diferencias?Object.keys(diferencias).reduce((a, b) => diferencias[a] < diferencias[b] ? a : b):""
                            valoresCampoMasCercano = obj[claveMasCercana];
                            
                        }
                    }
                });
            });
            setMdo(valoresCampoMasCercano)
            
            // Retorna los valores del campo más cercano
            return valoresCampoMasCercano ? Number(valoresCampoMasCercano[0][subcategoria]) : 0;
        } catch (error) {
            console.log(error);
            return '';
        }
    };

    useEffect(() => {
        const ordenesManoDeObra = order.map((ordenes)=>({
            ...ordenes,
            ManoDeObra: (order[0].ManoDeObra !== 0 && order[0].ManoDeObra != null) ? order[0].ManoDeObra : costomasreciente(ManoDeObraData, order[0].Tipo, order[0].TimeStamp)
        }))
    },[order])

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setSelectedOperations(prevState => {
            const newState = { ...prevState, [value]: checked };
            return newState;
        });
        
        // Actualiza el total basado en el estado actual
        setTotalValue(prevTotal => {
            if (mdo){
                if (checked) {
                    return prevTotal + mdo[0][value]; // Sumar si está marcado
                } else {
                    return prevTotal - mdo[0][value]; // Restar si está desmarcado
                }
            }
        });
    };
    
    useEffect(() => {
        // Verificar si mdo está definido y tiene al menos un elemento
        if (mdo && mdo.length > 0) {
            
            const ordenesManoDeObraTotal = order.map((ordenes) => ({
                ...ordenes,
                ManoDeObra: totalValue + (mdo[0]["ExcedenteKm"] * kilometros)
            }));
            setFinalOrder(ordenesManoDeObraTotal);
        }
    }, [totalValue, kilometros, mdo]); // Añadido 'mdo' a las dependencias
    
    
    const handleKilometrosChange = (event) => {
        setKilometros(event.target.value);
    };
    
    return (
        // <div>
        //     <div>
        //         <div style={{ overflow: "auto",height:'23rem'}} >
        //             <h2>Orden de servicio</h2>
        //             <p>Especificar brevemente que operacion de reparacion se aplico</p>
        //                 <div className=' col-12 d-flex justify-content-center'>
        //                     <textarea rows='5' style={{marginTop:'20px', width:'100%'}} name='motivo' ref={textarea}>
                                
        //                     </textarea>
        //                 </div>  
        //                 <div className=' col-12 d-flex justify-content-end'>    
        //                     <button className="btn btn-secondary" style={{height:'3rem', width:'7rem',marginTop:'20px' }} onClick={()=>{dataId()}}>
        //                         Confirmar
        //                     </button>
        //                 </div>
        //         </div>
        //     </div>
        // </div>
        <div>
            <div>
                {/* <div style={{ overflow: "auto", height: '23rem' }}> */}
                    <h2>Orden de servicio</h2>

                    <div className="col-12 d-flex flex-column" style={{ marginBottom: '20px'}}>
                        {/* Grupo de checkboxes para la primera lista de operaciones */}
            {order && order[0]?.Tipo === "VentiladorDeTecho"? 
                        <>
                        <h4 style={{ marginBottom: '20px'}}>Operaciones adicionales</h4>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="visitaDiagnosticoRetiroUnidadVTR" 
                                    checked={selectedOperations.visitaDiagnosticoRetiroUnidadVTR || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" >    
                                    Visita a domicilio + diagnóstico + eventual retiro de la unidad
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionDomicilioVTR"
                                    checked={selectedOperations.reparacionDomicilioVTR || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label">    
                                    Reparación en domicilio y verificación funcional
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionSencillaTallerVTR"
                                    checked={selectedOperations.reparacionSencillaTallerVTR || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label">  
                                    Reparación en taller y verificación funcional (Sencilla)
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionComplejaTallerVTR" id="reparacionComplejaTallerVTR"
                                    checked={selectedOperations.reparacionComplejaTallerVTR || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label">  
                                Reparación en taller y verificación funcional (Compleja)
                            </label>
                            </div>
                            <div className="form-check" style={{ marginBottom: '20px'}}>
                                <input className="form-check-input" type="checkbox" name="operation" value="reinstalaciónDomicilioVTR" id="reinstalaciónDomicilioVTR"
                                    checked={selectedOperations.reinstalaciónDomicilioVTR || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reinstalación en domicilio y verificación funcional
                                </label>
                            </div>
                            <label>
                            Ingrese excedente $ / km (a partir de los 15 km desde el taller, considerar IDA + VUELTA):
                        </label>
                            <input
                                type="number"
                                value={kilometros}
                                onChange={handleKilometrosChange}
                                placeholder="Ingrese valor"
                            />
                        </>
                        :<></>}
                        {/* Grupo de checkboxes para la segunda lista de operaciones */}
            {order && order[0]?.Tipo === "TermotanqueElectrico"? 
                        <>
                        <h4 style={{ marginBottom: '20px'}}>Operaciones adicionales</h4>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="visitaDiagnosticoRetiroUnidadTermot"
                                    checked={selectedOperations.visitaDiagnosticoRetiroUnidadTermot || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Visita a domicilio + diagnóstico + eventual retiro de la unidad
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionDomicilioTermot"
                                    checked={selectedOperations.reparacionDomicilioTermot || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reparación en domicilio y verificación funcional
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionSencillaTallerTermot"
                                    checked={selectedOperations.reparacionSencillaTallerTermot || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reparación en taller y verificación funcional
                                </label>
                            </div>    
                            <div className="form-check" style={{ marginBottom: '20px'}}>
                                <input className="form-check-input" type="checkbox" name="operation" value="reinstalaciónDomicilioTermot"
                                    checked={selectedOperations.reinstalaciónDomicilioTermot || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reinstalación en domicilio y verificación funcional
                                </label>
                            </div>
                            <label>
                            Ingrese excedente $ / km (a partir de los 15 km desde el taller, considerar IDA + VUELTA):
                        </label>
                            <input
                                type="number"
                                value={kilometros}
                                onChange={handleKilometrosChange}
                                placeholder="Ingrese valor"
                            />
                        </>
                        :<></>}
                        {/* Grupo de checkboxes para la tercera lista de operaciones */}
            {order && order[0]?.Tipo === "Heladera"? 
                        <>
                        <h4 style={{ marginBottom: '20px'}}>Operaciones adicionales</h4>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="visitaDiagnosticoHeladera"
                                    checked={selectedOperations.visitaDiagnosticoHeladera || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Visita a domicilio + diagnóstico
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionDomicilioHeladera"
                                    checked={selectedOperations.reparacionDomicilioHeladera || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reparacion en domicilio y verificación funcional
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="vacioCargaGasHeladera"
                                    checked={selectedOperations.vacioCargaGasHeladera || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Vacío y carga de gas, con verificación funcional
                                </label>
                            </div>    
                            <div className="form-check" style={{ marginBottom: '20px'}}>
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionTallerHeladera"
                                    checked={selectedOperations.reparacionTallerHeladera || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reparación en taller y verificación funcional 
                                </label>
                            </div>
                            <label>
                            Ingrese excedente $ / km (a partir de los 15 km desde el taller, considerar IDA + VUELTA):
                        </label>
                            <input
                                type="number"
                                value={kilometros}
                                onChange={handleKilometrosChange}
                                placeholder="Ingrese valor"
                            />
                        </>
                        :<></>}
                        {/* Grupo de checkboxes para la cuarta lista de operaciones */}
            {order && order[0]?.Tipo === "Freezer"? 
                        <>
                        <h4 style={{ marginBottom: '20px'}}>Operaciones adicionales</h4>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="visitaDiagnosticoFREEZER"
                                    checked={selectedOperations.visitaDiagnosticoFREEZER || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Visita a domicilio + diagnóstico
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionDomicilioFREEZER"
                                    checked={selectedOperations.reparacionDomicilioFREEZER || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reparacion en domicilio y verificación funcional
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="operation" value="vacioCargaGasFREEZER"
                                    checked={selectedOperations.vacioCargaGasFREEZER || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Vacío y carga de gas, con verificación funcional
                                </label>
                            </div>
                            <div className="form-check" style={{ marginBottom: '20px'}}>
                                <input className="form-check-input" type="checkbox" name="operation" value="reparacionTallerFREEZER"
                                    checked={selectedOperations.reparacionTallerFREEZER || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Reparación en taller y verificación funcional
                                </label>
                            </div>
                            <label>
                            Ingrese excedente $ / km (a partir de los 15 km desde el taller, considerar IDA + VUELTA):
                        </label>
                            <input
                                type="number"
                                value={kilometros}
                                onChange={handleKilometrosChange}
                                placeholder="Ingrese valor"
                            />
                        </>
                        :<></>}
                        <div className="form-check" style={{ marginTop: '20px'}}>
                        {mdo === 0? 
                            <>
                                <input className="form-check-input" className="form-check-input" type="checkbox" name="operation" value="Canje" 
                                    checked={selectedOperations.Canje || false}
                                    onChange={handleCheckboxChange}/>
                                <label className="form-check-label" > 
                                    Gestión de canje
                                </label>
                            </>
                            :<></>}
                        </div>
                        
                       
                    </div>

                    {/* Textarea for additional details */}
                    <p>Especificar brevemente qué operación de reparación se aplicó</p>
                    <div className='col-12 d-flex justify-content-center'>
                        <textarea rows='5' style={{ marginTop: '20px', width: '100%' }} name='motivo' ref={textarea}>
                        </textarea>
                    </div>  
                    
                    {/* Confirmation button */}
                    <div className='col-12 d-flex justify-content-end'>    
                        <button className="btn btn-secondary" style={{ height: '3rem', width: '7rem', marginTop: '20px' }} onClick={() => { dataId() }}>
                            Confirmar
                        </button>
                    </div>
                {/* </div> */}
            </div>
        </div>

        // <div className="modal"
        //         id="Modal1"
        //         tabIndex={-1}
        //         aria-labelledby="Modal1"
        //         aria-hidden="true"
        //         style={{marginBottom:'20px' ,marginTop:'20px', overflow: "auto"}}ref={OrderRef}>
        //     <div className="modal-dialog modal-fullscreen-lg-down modal-lg">
        //         <div className='container modal-content' style={{ overflow: "auto",height:'23rem'}} >
        //             <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button>
        //             <h2>Orden de servicio</h2>
        //             <p>Especificar brevemente que operacion de reparacion se aplico</p>
        //                 <div className=' col-12 d-flex justify-content-center'>
        //                     <textarea rows='5' style={{marginTop:'20px', width:'100%'}} name='motivo' ref={textarea}>
                                
        //                     </textarea>
        //                 </div>  
        //                 <div className=' col-12 d-flex justify-content-end'>    
        //                     <button className="btn btn-secondary" style={{height:'3rem', width:'7rem',marginTop:'20px' }} onClick={()=>{dataId()}}>
        //                         Confirmar
        //                     </button>
        //                 </div>
        //         </div>
        //     </div>
        // </div>
    );
  };